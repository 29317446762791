// External Imports
import { createSelector } from 'reselect';
import { compile } from 'path-to-regexp';

// Internal Imports
import { editionPathTypeOrder, HOME } from '../config/types/path.mjs';
import { checkUsEnglish } from '../utils/checkUsEnglish.mjs';
import {
  getLanguageCodeFromPath,
  getLinkParts,
  isAbsolute,
  isBabbelHostname,
  prependEditionHostname,
  hostIsStaging,
} from '../utils/url.mjs';
import { getFirstMatch } from './selectFirstMatch.mjs';
import { getEditionFromLocale } from './selectEdition.mjs';
import { selectMatch } from './selectMatch.mjs';
import { selectPathMap } from './selectPathMap.mjs';

// Local Functions
const createSelectEditionPath = (link) =>
  createSelector([selectPathMap, selectMatch], (pathMap, { params }) => {
    const linkParts = getLinkParts(link);
    const linkQuery = linkParts.query || '';
    const isHttpLink = /^https?:$/.test(linkParts.protocol); // For example: email links are non-HTTP
    const isAbsoluteLink = isAbsolute(link);
    const { hostname } = linkParts;
    const isAbsoluteNonBabbelLink =
      isAbsoluteLink && !isBabbelHostname(hostname) && !hostIsStaging(hostname);

    if (!isHttpLink || isAbsoluteNonBabbelLink) {
      return link;
    }

    const { pathname } = linkParts;

    const editionMatch = getFirstMatch(editionPathTypeOrder, pathMap, pathname);

    if (!editionMatch) {
      return isAbsoluteLink ? link : prependEditionHostname(params.language, pathname);
    }

    const pathLanguageCode = getLanguageCodeFromPath(pathname);
    const newLanguageCode =
      HOME === editionMatch.pathType
        ? checkUsEnglish(pathLanguageCode)
        : getEditionFromLocale(pathLanguageCode);
    const pathTypeToPath = compile(pathMap[editionMatch.pathType]);
    const convertedPath = pathTypeToPath({
      ...editionMatch.params,
      language: newLanguageCode,
    });
    const fullLink = prependEditionHostname(newLanguageCode, convertedPath) + linkQuery;
    return fullLink;
  });

// Module Exports
export { createSelectEditionPath };
