// External Imports
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

// Internal Imports
import { actionArticleLinkClicked } from '../../actions/index.mjs';
import { CampaignsTracker } from '../Tracking/CampaignsTracker.jsx';
import { CONTENT_ITEM, LANGUAGES } from '../../config/types/prop.mjs';
import { ContentScriptEmbedHandler } from './ContentScriptEmbedHandler.jsx';
import { createSelectComponentByContentItem } from '../../selectors/createSelectComponent.mjs';
import { createSelectContentItemBySlug } from '../../selectors/createSelectContentItem.mjs';
import { createSelectEditionPath } from '../../selectors/createSelectEditionPath.mjs';
import { selectContentType } from '../../selectors/selectMatch.mjs';
import { selectEdition } from '../../selectors/selectEdition.mjs';
import { selectIsInProductMagazine } from '../../selectors/selectIsInProductMagazine.mjs';
import { getMetaImageDimensions } from '../../utils/metadata.mjs';
import { selectRelatedContentItemsByItem } from '../../selectors/selectRelatedItems.mjs';
import { selectRemoveLinks } from '../../selectors/selectRemoveLinks.mjs';
import { HeaderLinks } from '../HeaderLinks/HeaderLinks.jsx';
import { isAbsolute } from '../../utils/url.mjs';
import { Meta } from '../Meta/Meta.jsx';
import { options } from '../../config/reactRedux.mjs';
import { SINGLE } from '../../config/types/content/component.mjs';
import { WithLoader } from '../Hoc/WithLoader/WithLoader.jsx';
import {
  selectIsActiveContentItemLoading,
  selectIsActiveContentItemLoadingRelated,
} from '../../selectors/selectLoading.mjs';

// Component Definition
class ContentItem extends Component {
  constructor(props) {
    super(props);
    this.contentContainerRef = React.createRef();
    this.trackableLinksTearDown = () => {};
  }

  componentDidMount() {
    this.setupTrackableLinks();
  }

  componentDidUpdate() {
    this.setupTrackableLinks();
  }

  componentWillUnmount() {
    this.trackableLinksTearDown();
  }

  setupTrackableLinks() {
    this.trackableLinksTearDown();

    const { createPath, isInProductMagazine, history, onLinkClicked } = this.props;
    const container = this.contentContainerRef.current;
    const linksList = [...container.querySelectorAll('a')];

    const inProductNavigation = (clickEvent) => {
      const link = createPath(clickEvent.currentTarget.href);
      if (isAbsolute(link)) {
        clickEvent.preventDefault();
      } else {
        clickEvent.preventDefault();
        history.push(link);
      }
    };

    const trackClickEvent = (clickEvent) => {
      const isTargetAnAnchorTag = clickEvent.target.localName === 'a';
      // Older articles wrap their anchor text within span tags whenever there is a particular
      // style applied to the text. The following condition takes that into consideration
      const currentTarget = isTargetAnAnchorTag
        ? clickEvent.target
        : clickEvent.target.parentElement;
      const { href, textContent } = currentTarget;
      onLinkClicked({
        text: textContent,
        url: href,
      });
    };

    const clickEventListener = (clickEvent) => {
      trackClickEvent(clickEvent);
      if (isInProductMagazine) {
        inProductNavigation(clickEvent);
      }
    };

    const auxClickEventListener = (clickEvent) => {
      // tracks only mouse wheel clicks
      if (clickEvent.button === 1) {
        return clickEventListener(clickEvent);
      }

      return false;
    };

    for (const linkElement of linksList) {
      linkElement.addEventListener('click', clickEventListener);
      linkElement.addEventListener('auxclick', auxClickEventListener);
    }

    this.trackableLinksTearDown = () => {
      for (const linkElement of linksList) {
        linkElement.removeEventListener('click', clickEventListener);
        linkElement.removeEventListener('auxclick', auxClickEventListener);
      }
    };
  }

  buildImageMetaElements() {
    const { item } = this.props;
    const metaList = item.metadata.headerMeta;
    const dimensions = getMetaImageDimensions(metaList);
    return (
      dimensions && (
        <Helmet>
          <meta property="og:image:height" content={dimensions.height} />
          <meta property="og:image:width" content={dimensions.width} />
        </Helmet>
      )
    );
  }

  render() {
    const {
      component: ContentItemComponent,
      contentFiltered,
      contentScripts,
      currentEdition,
      isInProductMagazine,
      isLoadingRelated,
      item,
      related,
    } = this.props;

    const {
      metadata: {
        canonicalUrl,
        coauthors: [author],
        headerMeta,
        headerTitle,
        languageHreflang,
        tracking: { shortlinkCode },
      },
    } = item;

    return (
      <div>
        {headerMeta && <Meta metaTags={headerMeta} title={headerTitle} />}
        {this.buildImageMetaElements()}
        <Helmet>
          <link rel="author" href={author.relativePath} />
        </Helmet>
        <CampaignsTracker shortlinkCode={shortlinkCode} />
        <ContentScriptEmbedHandler contentScripts={contentScripts} />
        <HeaderLinks canonicalUrl={canonicalUrl} languageHreflang={languageHreflang} />
        <ContentItemComponent
          item={item}
          contentFiltered={contentFiltered}
          related={related}
          currentEdition={currentEdition}
          contentContainerRef={this.contentContainerRef}
          isInProductMagazine={isInProductMagazine}
          isLoadingRelated={isLoadingRelated}
        />
      </div>
    );
  }
}

ContentItem.propTypes = {
  // abTester: PropTypes.shape({
  //   isLoading: PropTypes.bool.isRequired,
  //   isInExperimentGroup: PropTypes.bool.isRequired,
  //   wasQueried: PropTypes.bool.isRequired,
  // }).isRequired,
  component: PropTypes.elementType.isRequired,
  contentFiltered: PropTypes.string.isRequired,
  contentScripts: PropTypes.arrayOf(PropTypes.string).isRequired,
  createPath: PropTypes.func.isRequired,
  currentEdition: LANGUAGES.isRequired,
  history: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types -- use a TypeScript type eventually
  isInProductMagazine: PropTypes.bool.isRequired,
  isLoadingRelated: PropTypes.bool.isRequired,
  item: PropTypes.shape(CONTENT_ITEM).isRequired,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  onLinkClicked: PropTypes.func.isRequired,
  related: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types -- unsure of the object shape
};

const mapStateToProps = (state, props) => {
  const contentType = selectContentType(state);
  const selectContentItemBySlug = createSelectContentItemBySlug(contentType);
  const item = selectContentItemBySlug(state, props);
  const selectComponent = createSelectComponentByContentItem(item, SINGLE);
  const component = selectComponent(state);
  const loading = selectIsActiveContentItemLoading(state) || !component;
  const related = selectRelatedContentItemsByItem(state, item);
  const isInProductMagazine = selectIsInProductMagazine(state);
  const { postProcessedData = {} } = item || {};
  const filterLinksIfNeeded = isInProductMagazine ? selectRemoveLinks(state) : (content) => content;
  const contentFiltered = filterLinksIfNeeded(postProcessedData.filteredContent?.content || '');
  const createPath = (path) => createSelectEditionPath(path)(state);

  return {
    component,
    contentFiltered,
    contentScripts: postProcessedData.scripts,
    createPath,
    currentEdition: selectEdition(state),
    isInProductMagazine,
    isLoadingRelated: selectIsActiveContentItemLoadingRelated(state),
    item,
    loading,
    related,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onLinkClicked: actionArticleLinkClicked,
    },
    dispatch,
  );

const withRedux = connect(mapStateToProps, mapDispatchToProps, undefined, options);
const ContentItemHoc = withRouter(withRedux(WithLoader(ContentItem)));

// Module Exports
export { ContentItemHoc as ContentItem };
