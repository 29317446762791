// External Imports
import has from 'lodash/fp/has.js';

// Internal Imports
import { TwitterEmbedConfiguration } from '../components/ContentEmbedProvider/TwitterEmbedConfiguration.jsx';
import { SoundciteEmbedConfiguration } from '../components/ContentEmbedProvider/SoundciteEmbedConfiguration.jsx';

// Local Variables
const instagramEmbed = {
  checkSrc: (checkUrl) => checkUrl.includes('instagram.com'),
  id: 'instagram',
  isReadyForManualProcessing: () => has('instgrm.Embeds.process', window),
  processPageEmbeds: () => window.instgrm.Embeds.process(),
};

const playbuzzEmbed = {
  checkSrc: (checkUrl) => checkUrl.includes('playbuzz.com'),
  id: 'playbuzz',
  isReadyForManualProcessing: () => has('Playbuzz.render', window),
  processPageEmbeds: () => window.Playbuzz.render(),
};
const twitterEmbed = {
  checkSrc: (checkUrl) => checkUrl.includes('twitter.com'),
  configurationComponent: TwitterEmbedConfiguration,
  id: 'twitter',
  isReadyForManualProcessing: () => has('twttr.widgets.load', window),
  processPageEmbeds: () => window.twttr.widgets.load(),
};

/*
Soundcite requires manual unloading to be correctly reloaded because of the way it works.
During its loading phase, it looks for embeds to be processed within the DOM based on tags' classnames.
For those, it will extract the URL of the audio clip and load it by adding to the DOM an <audio> tag with the class name `soundcite-audio`.
So far so good, no comes the main problem, soundcite uses as IDs the index of the order in which elements where found and never attempt to clean up
existing `.soundcite-audio` tags.
For us, this means that when switching between articles, the second article to be loaded will play sounds from the first one.

A little example of how this looks like:

User visits a page with a `Hello world` soundcite embed, at first the DOM looks like this:
<body>
  <div id="root-of-dom-controlled-by-react">
    <article>
      <button class="soundcite" data-url="https://some-domain.com/hello-world.mp3">Hello world!</button>
    </article>
  </div>
</body>

Once soundcite has loaded, it has appended an audio tag for the sound, so the DOM now looks like this:
<body>
  <div id="root-of-dom-controlled-by-react">
    <article>
      <button class="soundcite" data-url="https://some-domain.com/hello-world.mp3">Hello world!</button>
    </article>
  </div>
  <audio class="soundcite-audio" src="https://some-domain.com/hello-world.mp3" />
</body>

And when clicking the hello world button, the soundcite script looks for the `.soundcite-audio` at index 0.

Now the user visits another article, which contains a "Oh no this is broken" soundcite embed, the DOM looks like this:
<body>
  <div id="root-of-dom-controlled-by-react">
    <article>
      <button class="soundcite" data-url="https://some-domain.com/oh-no-this-is-broken.mp3">Oh no this is broken!</button>
    </article>
  </div>
  <audio class="soundcite-audio" src="https://some-domain.com/hello-world.mp3" />
</body>

Here you can already observe the incoming problem, given the soundcite script is outside of the realm of React and
nothing is clearing the audio tags it inserts, we now have a stale audio tag in the DOM, but this is not the end.
After the reloading of the soundcite script on this page, we get to this DOM:
<body>
  <div id="root-of-dom-controlled-by-react">
    <article>
      <button class="soundcite" data-url="https://some-domain.com/oh-no-this-is-broken.mp3">Oh no this is broken!</button>
    </article>
  </div>
  <audio class="soundcite-audio" src="https://some-domain.com/hello-world.mp3" />
  <audio class="soundcite-audio" src="https://some-domain.com/oh-no-this-is-broken.mp3" />
</body>

The problem now is that when clicking on the button "Oh no this is broken!", soundcite believes its ID to be the index 0...
This means it'll play the first `.soundcite-audio` tag, which means playing `Hello word!`... this is indeed broken.

The solution for us then is to make sure to remove all `.soundcite-audio` tags when we unload the soundcite script, so that
we don't have any stale audio in the DOM.
*/
const soundciteEmbed = {
  checkSrc: (checkUrl) => checkUrl.includes('cdn.knightlab.com/libs/soundcite/'),
  configurationComponent: SoundciteEmbedConfiguration,
  id: 'soundcite',
  onUnload: () => {
    document.querySelector('.soundcite-audio').remove();
  },
};

const knownEmbeds = [instagramEmbed, twitterEmbed, playbuzzEmbed, soundciteEmbed];

// Local Functions
const isReloadableEmbed = (embed) =>
  Boolean(embed?.isReadyForManualProcessing) && Boolean(embed?.processPageEmbeds);

const findKnownEmbed = (checkUrl) =>
  knownEmbeds.find((embedStruct) => embedStruct.checkSrc(checkUrl));

// Module Exports
export { findKnownEmbed, isReloadableEmbed, knownEmbeds };
