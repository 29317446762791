// External Imports
import { pxToRem, styled } from '@lessonnine/design-system.lib';
import tokens from '@lessonnine/design-tokens.lib/dist/json/variables.json';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

// Internal Imports
import { setRichTextInnerHTML } from '../../utils/setRichTextInnerHtml.jsx';
import quoteImg from '../../assets/icons/quotes.svg';
import { breakpoints } from '../../config/css/breakpoints.mjs';
import { bodyTextFullbleedWidth, siteGutter } from '../../config/css/ui.mjs';
import { separatorStyles } from '../../sharedStyles/separator.mjs';

const quoteIconWidth = pxToRem(35);
const quoteIconHeight = pxToRem(28);
const quoteIconOffsetPosition = pxToRem(40);
const pullquoteOffsetPosition = `calc(${quoteIconHeight} + ${quoteIconOffsetPosition})`;

// Local Variables
const RichTextStyle = styled.div`
  color: ${({ theme }) => theme.color.surface.foreground.primary};
  display: flex;
  flex-direction: column;
  font-family: ${tokens.fonts.default.name};
  font-size: 1rem;
  line-height: 1.875rem;
  max-width: 42.5rem;

  /* TODO: all the styles below (with the exception of responsiveVideoWrapper and blockquote/pullquotes) should come from design-system, but it would require parsing the article content and replacing with the right components, which we need to investigate further */
  > * {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  iframe[src*='youtube.com/embed/'] {
    aspect-ratio: 16 / 9;
    height: auto;
    max-width: 100%;
    width: 100%;
  }

  > img,
  p > img {
    height: auto;
    margin: 0 0 1.5rem;
    max-width: 100%;
  }

  /* stylelint-disable-next-line selector-class-pattern -- TODO: this class comes from the BE so we should fix it on both sides if we want to fix it */
  .embed__responsiveVideoWrapper {
    width: 100%;

    > * {
      aspect-ratio: 16 / 9;
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }

  .wp-block-quote {
    border-left: ${pxToRem(2)} solid ${({ theme }) => theme.color.progress.foreground};
    font-size: ${pxToRem(18)};
    font-weight: 400;
    line-height: 1.94;
    margin: ${pxToRem(100)} auto ${pxToRem(100)};
    padding-left: ${pxToRem(50)};
    position: relative;

    cite {
      color: ${({ theme }) => theme.color.surface.foreground.tertiary};
      display: block;
      margin-top: ${pxToRem(35)};
    }

    /* TODO: This won't be currently supported, but I can't find what it's supposed to do, I'll check with Wyatt
    &:global(.instagram-media),
    &:global(.instagram-media-registered),
    &:global(.twitter-tweet) {
      @include loadingSpinner(../assets/icons);

      background-color: transparent;
      background-size: contain;
      height: ${pxToRem(200)};
      margin-left: 0;
      width: ${pxToRem(200)};
    }

    &:global(.instagram-media) *,
    &:global(.instagram-media-registered) *,
    &:global(.twitter-tweet) * {
      display: none;
    }
    */
  }

  blockquote {
    border-left: ${pxToRem(4)} solid ${({ theme }) => theme.color.surface.layer.border};
    padding-left: ${pxToRem(18)};

    &.tiktok-embed {
      border-left: 0;
      padding-left: 0;
    }
  }

  .pullquote,
  .wp-block-pullquote > blockquote {
    font-family: ${tokens.fonts.default.name};
    font-size: ${pxToRem(30)};
    font-weight: 600;
    line-height: 1.5;
    margin-bottom: ${pxToRem(100)};
    margin-top: calc(${pxToRem(100)} + ${pullquoteOffsetPosition});
    position: relative;
    text-align: center;

    cite {
      color: ${({ theme }) => theme.color.surface.foreground.tertiary};
      display: block;
      font-size: ${pxToRem(18)};
      font-weight: 400;
      margin-top: ${pxToRem(25)};
    }

    &::before {
      background-image: url(${quoteImg});
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      height: ${quoteIconHeight};
      left: calc(50% - (${quoteIconWidth} / 2));
      position: absolute;
      top: calc(${pullquoteOffsetPosition} * -1);
      width: ${quoteIconWidth};
    }
  }

  .has-small-font-size {
    font-size: ${pxToRem(12)};

    @media ${breakpoints.tabletLandscape.min} {
      font-size: ${pxToRem(14)};
    }
  }

  /* stylelint-disable-next-line selector-class-pattern -- class name used on WordPress */
  .paragraphQuoteWithAttribution {
    .quote {
      margin-bottom: ${pxToRem(24)};

      @media ${breakpoints.tabletPortrait.max} {
        margin-bottom: ${pxToRem(16)};
      }
    }

    .attribution {
      color: ${({ theme }) => theme.color.surface.foreground.secondary};
      font-size: ${pxToRem(20)};
      line-height: calc(21 / 20);
      margin-left: ${pxToRem(1)};

      @media ${breakpoints.tabletPortrait.max} {
        font-size: ${pxToRem(18)};
        line-height: calc(20 / 18);
      }
    }
  }

  .alignleft {
    display: inline;
    float: left;
    margin-right: ${pxToRem(20)};
  }

  .alignright {
    display: inline;
    float: right;
    margin-left: ${pxToRem(20)};
  }

  .alignleft,
  .alignright {
    height: auto;

    img[height] {
      height: auto;
    }

    @media ${breakpoints.tabletLandscape.max} {
      max-width: calc(50% + ${pxToRem(siteGutter)});
    }

    @media ${breakpoints.tabletLandscape.min} {
      max-width: calc(${bodyTextFullbleedWidth} / 2);
    }

    @media ${breakpoints.mobile.max} {
      max-width: 100%;
      text-align: center;
      width: 100%;
    }
  }

  .aligncenter,
  .alignnone {
    clear: both;
    display: flex;
    flex-flow: column wrap;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    text-align: center;

    /* Somehow lazyload does not respect the max-width, so instead also setting the width to 100% */
    &.lazyload {
      width: 100%;
    }

    img[height] {
      height: auto;
    }
  }

  .wp-caption {
    margin-bottom: 1.5rem;
    padding-top: ${pxToRem(5)};

    img {
      border: 0 none;
      margin: 0 0 ${pxToRem(5)} 0;
      max-width: 100%;
    }
  }

  .wp-caption-text {
    font-size: ${pxToRem(13)};
    line-height: 1.55;
    margin: 0;

    @media ${breakpoints.tabletLandscape.max} {
      padding: 0 ${pxToRem(10)};
    }
  }

  .wp-block-image {
    clear: both;
    display: block;
    height: auto;
    margin-bottom: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    padding-top: ${pxToRem(5)};
    text-align: center;

    img {
      border: 0 none;
      margin: 0 0 ${pxToRem(5)} 0;
      max-width: 100%;
    }

    img[height] {
      height: auto;
    }

    figcaption {
      font-size: ${pxToRem(13)};
      line-height: 1.55;
      margin: 0;

      @media ${breakpoints.tabletLandscape.max} {
        padding: 0 ${pxToRem(10)};
      }
    }
  }

  hr,
  .wp-block-separator {
    ${separatorStyles};
  }

  .tiktok-embed {
    /* Set max-width to ensure TikTok embed only displays the video, avoiding background color from the embed. TikTok set the width as 325px for the video when the embed code is generated */
    max-width: ${pxToRem(325)};

    > :not(iframe) {
      display: none;
    }
  }

  > h3,
  h4,
  h5 {
    margin-bottom: 1.2rem;
  }

  /* Minimum styles copied from the design-system Link component */
  a {
    color: ${({ theme }) => theme.color.surface.foreground.primary};

    :hover {
      color: ${({ theme }) => theme.color.interactive.primary.hover.background};
    }

    :active {
      color: ${({ theme }) => theme.color.interactive.primary.active.background};
    }
  }

  /* don't underline images wrapped in <a> tags */
  a[href*='.jpg'],
  a[href*='.png'],
  a[href*='.webp'] {
    border: 0;
  }

  /* Minimum styles copied from the design-system Header components */

  /*
    In the following code there are two instances of "important" being used.
    Because wordpress allows the setting of font weights in the article editor
    (which we then recieve as inlined styles on spans),
    important was required to override those predefined font weights.
    This mismatch was caused by switching fonts from Leitura to Milliard,
    and the difference in font weights between them.
    TODO: Once wordpress font weight changing capability is removed, delete these !important span rules
  */
  h2 {
    font-family: ${tokens.fonts.expressive.name};
    font-size: ${pxToRem(24)};
    font-weight: 500;
    line-height: 1.5;

    > span {
      font-weight: 500 !important;
    }

    @media (${breakpoints.tabletLandscape.min}) {
      font-size: ${pxToRem(30)};
      line-height: 1.625;
    }
  }

  h3 {
    font-family: ${tokens.fonts.default.name};
    font-size: ${pxToRem(19.008)};
    font-weight: 500;
    line-height: 1.625;

    > span {
      font-weight: 500 !important;
    }

    @media (${breakpoints.mobile.max}) {
      font-size: ${pxToRem(18)};
      line-height: 1.5;
    }
  }

  h4 {
    font-family: ${tokens.fonts.expressive.name};
    font-size: ${tokens.size.font.base};
    font-weight: 500;
    line-height: ${tokens.size.font.base};

    > span {
      font-weight: 500 !important;
    }
  }

  h5,
  h6 {
    font-family: ${tokens.fonts.expressive.name};
    font-size: ${tokens.size.font.base};
    font-weight: 400;
    line-height: ${tokens.size.font.base};

    > span {
      font-weight: 400 !important;
    }
  }

  ul {
    list-style: none;
    margin-left: 0;
    padding: 0;
    position: relative;

    li {
      padding-left: ${pxToRem(32)};

      &::before {
        color: ${({ theme }) => theme.color.progress.foreground};
        content: '◼';
        left: 0;
        position: absolute;

        @media ${breakpoints.tabletLandscape.max} {
          content: '■';
        }
      }
    }
  }

  ol {
    counter-reset: ordered-list;
    list-style: none;
    margin-left: 0;
    padding: 0;

    li {
      counter-increment: ordered-list;
      padding-left: ${pxToRem(32)};
      text-indent: ${pxToRem(-32)};

      &::before {
        color: ${({ theme }) => theme.color.progress.foreground};
        content: counter(ordered-list);
        direction: rtl;
        display: inline-block;
        font-weight: 700;
        margin-left: ${pxToRem(-32)};
        margin-right: ${pxToRem(48)};
        text-align: right;
        width: ${pxToRem(16)};
      }
    }
  }

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  i {
    font-style: italic;
  }

  table {
    border-collapse: collapse;
    text-align: left;
    width: 100%;

    td,
    th {
      padding: ${pxToRem(10)};
    }

    td {
      border: ${pxToRem(1)} solid ${({ theme }) => theme.color.surface.layer.border};
    }

    thead td,
    th {
      border: none;
      font-weight: 700;
    }

    @media ${breakpoints.mobile.max} {
      display: block;
      overflow-x: auto;
    }
  }

  @media (${breakpoints.tabletLandscape.min}) {
    font-size: ${pxToRem(18)};
  }
`;

// Component Definition
function RichTextBase({ contextProps, richText, ...props }, ref) {
  return (
    <RichTextStyle
      // eslint-disable-next-line react/jsx-props-no-spreading -- this acts as a generic purpose component, so spreading is useful
      {...props}
      dangerouslySetInnerHTML={setRichTextInnerHTML(richText, contextProps)}
      ref={ref}
    />
  );
}

RichTextBase.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types -- TODO: I didn't find any usage for this, maybe we could just remove it?
  contextProps: PropTypes.object,
  richText: PropTypes.string.isRequired,
};

RichTextBase.defaultProps = {
  contextProps: undefined,
};

const RichText = forwardRef(RichTextBase);

// Module Exports
export { RichText };
