/* eslint-disable unicorn/filename-case -- HOC are outside of the PascalCase naming */
/* eslint-enable unicorn/filename-case -- HOC are outside of the PascalCase naming */
// External Imports
import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/fp/omit';
import { connect } from 'react-redux';
import { Link as ReactRouterLink } from 'react-router-dom';

// Internal Imports
import { options } from '../../config/reactRedux.mjs';
import { isAbsolute, isMailto } from '../../utils/url.mjs';
import { createSelectEditionPath } from '../../selectors/createSelectEditionPath.mjs';

const defaultConfiguration = { useForwardedAs: false };

// HOC Definition
function withLinkLogicBase(LinkComponent, { useForwardedAs }) {
  function Component({ dangerouslySetInnerHTML, pathWithLanguageEdition, ...restProps }) {
    const childProps = omit(['isFooterLink', 'to'], restProps);

    const isStandardUrl = isAbsolute(pathWithLanguageEdition) || isMailto(pathWithLanguageEdition);
    const standardUrlProps = {
      href: pathWithLanguageEdition,
    };
    const clientSideUrlProps = {
      to: pathWithLanguageEdition,
      ...(useForwardedAs
        ? {
            forwardedAs: ReactRouterLink,
          }
        : {
            as: ReactRouterLink,
          }),
    };

    // The DS Link always adds children, causing a React Error that we can't use both dangerouslySetInnerHTML and
    // children, so mitigating that by using a span as child
    if (dangerouslySetInnerHTML) {
      // eslint-disable-next-line react/no-danger -- we are often setting rich content in links, so no choice but to use setInnerHTML
      childProps.children = <span dangerouslySetInnerHTML={dangerouslySetInnerHTML} />;
    }

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading -- spreading to forward standard link props to the child component + using conditional props
      <LinkComponent {...childProps} {...(isStandardUrl ? standardUrlProps : clientSideUrlProps)} />
    );
  }
  Component.propTypes = {
    ...ReactRouterLink.propTypes,
    pathWithLanguageEdition: PropTypes.string.isRequired,
  };

  return Component;
}

const mapStateToProps = (state, { to, isFooterLink }) => ({
  pathWithLanguageEdition: isFooterLink ? to : createSelectEditionPath(to)(state),
});

const withRedux = connect(mapStateToProps, {}, undefined, options);

const withLinkLogic = (LinkComponent, configuration = defaultConfiguration) =>
  withRedux(withLinkLogicBase(LinkComponent, configuration));

// Module Exports
export { withLinkLogic };
