// External Imports
import compose from 'lodash/fp/flow.js';
import flatten from 'lodash/fp/flatten.js';
import get from 'lodash/fp/get.js';
import getOr from 'lodash/fp/getOr.js';
import groupBy from 'lodash/fp/groupBy.js';
import map from 'lodash/fp/map.js';
import mapValues from 'lodash/fp/mapValues.js';
import pick from 'lodash/fp/pick.js';
import omit from 'lodash/fp/omit.js';
import merge from 'lodash/fp/merge.js';
import mutate from 'lodash/set.js';
import set from 'lodash/fp/set.js';

// Internal Imports
import { contentRelationMap } from '../../config/types/content/relations.mjs';
import { selectPostRelationsMap } from '../../selectors/selectRelatedItems.mjs';
import { mapToValues, camelCaseKeysDeep, mergeObjectKeysById } from '../../utils/dataUtils.mjs';

// Local Functions
const createMapTo = (name) => mapToValues((value, id) => ({ id, [name]: value }));
/**
 * Create site options action payload from WPAPI response.
 * @param {object} response WPAPI alley-react options response
 * @returns {object}   receive site options payload
 */
function createSiteOptionsPayload(response) {
  const {
    info,
    postTypes,
    taxonomies,
    footer,
    redirects,
    socialMedia,
    languages,
    language_picker: languagePicker,
    menu_items: menuItems,
    translations,
  } = response;

  return {
    contentType: postTypes.map((slug) => ({ id: slug, slug })),
    footer: camelCaseKeysDeep(footer),
    info: createMapTo('value')(info),
    languagePicker: camelCaseKeysDeep(languagePicker),
    languages: camelCaseKeysDeep(languages),
    menu: camelCaseKeysDeep(menuItems),
    redirect: redirects,
    socialMedia: camelCaseKeysDeep(socialMedia),
    taxonomy: taxonomies.map((slug) => ({ id: slug, slug })),
    translations,
  };
}

/**
 * Create content action payload from WPAPI response.
 * @param {Array} response
 * @param {number} page current page
 * @param {object} match router match object
 * @returns {object}    receive content item payload
 */
function createContentPayload(response, page, match) {
  if (response.length === 0) {
    return { meta: {} };
  }

  // eslint-disable-next-line no-underscore-dangle -- Wordpress API shape
  const { total, currentPage, totalPages } = response._paging;
  if (currentPage > totalPages) {
    return { meta: {} };
  }

  const contentType = response[0].type;
  const mergeEntityMap = (entityMap, entity) => {
    const item = convertPost(entity);
    const relatedPostsMap = extractRelatedPosts(contentRelationMap, item.metadata, match);

    return mergeObjectKeysById(entityMap, {
      // eslint-disable-next-line no-underscore-dangle -- Wordpress API shape
      ...convertPostRelations(entity._embedded),
      ...relatedPostsMap,
      [entity.type]: relatedPostsMap[entity.type]
        ? [...relatedPostsMap[entity.type], item]
        : [item],
    });
  };

  let entityMap;
  for (const entity of response) {
    entityMap = mergeEntityMap(entityMap, entity);
  }

  return {
    ...entityMap,
    meta: {
      contentType,
      page,
      refs: response.map(pick(['id', 'type'])),
      total,
      totalPages,
    },
  };
}

/**
 * Merge "full" term objects into content payload.
 * @param {object}   payload    content payload
 * @param {string}   taxonomy   the taxonomy type of the term
 * @param {object[]} fullTerms  a collection of "full" term objects
 * @return {object}
 */
function mergeTerms(payload, taxonomy, fullTerms) {
  const termIds = new Set(fullTerms.map(({ id }) => id));

  // Term objects embedded within content items will only have an
  // "abbreviated" sub shape object.
  const abbreviatedTerms = payload[taxonomy] || [];

  // Merge full term objects into content payload, by removing any "abbreviated"
  // shape term objects and replacing them with the "full" shape objects.
  const terms = [...abbreviatedTerms.filter(({ id }) => !termIds.has(id)), ...fullTerms];

  return set(taxonomy, terms, payload);
}

/**
 * Convert WPAPI post entity into content item shape.
 * @param {object} entity WPAPI post entity
 * @returns {object}
 */
function convertPost(entity) {
  const fields = ['id', 'date', 'modified', 'slug', 'path'];
  const contentFields = ['title', 'content', 'excerpt'];

  // eslint-disable-next-line no-underscore-dangle -- WordPress API returns _embedded for posts with additional metadata like featured image alt text
  const featuredImageAlt = entity._embedded?.['wp:featuredmedia']?.[0]?.alt_text ?? '';

  const renderedContentFieldsAsMainKeys = {};
  for (const field of contentFields) {
    if (entity[field]) {
      renderedContentFieldsAsMainKeys[field] = entity[field].rendered;
    }
  }

  return {
    ...pick(fields, entity),
    ...renderedContentFieldsAsMainKeys,
    metadata: {
      ...transformMeta(
        camelCaseKeysDeep(omit([...fields, ...contentFields, '_embedded', '_links'], entity)),
      ),
      featuredImageAlt,
    },
  };
}

/**
 * @param {object} metadata - WPAPI post entity
 * @returns {object}
 */
function transformMeta(metadata) {
  return compose(merge(metadata.metaData || {}), omit(['metaData']))(metadata);
}

/**
 * Convert WPAPI post entity _embeded field into related post entities.
 * @param {object} embedded WPAPI post entity _embedded field
 * @returns {object}
 */
function convertPostRelations(embedded) {
  if (!embedded) {
    return {};
  }

  const groupTerms = compose(
    flatten,
    groupBy('taxonomy'),
    mapValues((terms) => terms.map((term) => convertTerm(term))),
  );

  return {
    ...groupTerms(getOr([], 'wp:term', embedded)),
  };
}

/**
 * Convert WPAPI term entity into term shape.
 * @param {object} entity
 * @returns {object}
 */
function convertTerm(entity) {
  return pick(['id', 'name', 'slug', 'taxonomy'], entity);
}

/**
 * Convert content item related post metadata into object keyed by content type
 *
 * @param {object} relationMap map of post relationships
 * @param {object} data metadata for content item
 * @param {object} match
 * @returns {object}
 */
function extractRelatedPosts(relationMap, data, match) {
  const relations = selectPostRelationsMap(contentRelationMap, match, data);
  if (!relations) {
    return [];
  }

  const paths = Object.keys(relations).filter((path) => relations[path].eager);

  let result = {};
  for (const path of paths) {
    const posts = get(path, data) || [];
    const items = posts.map((post) => convertPost(post));
    const itemsGrouped = groupBy(get('metadata.type'), items);

    result = mergeObjectKeysById(result, itemsGrouped);

    const mapToIds = compose(get(path), map('id'));
    mutate(data, path, mapToIds(data));
  }

  return result;
}

/**
 * Retry request if it failed once
 * @param {function} request
 * @returns {promise}
 */
async function makeRequestWithRetry(callback) {
  try {
    return await callback();
  } catch (error) {
    if (error.status >= 500) {
      return callback();
    }
    throw error;
  }
}

// Module Exports
export {
  convertPost,
  createContentPayload,
  createSiteOptionsPayload,
  makeRequestWithRetry,
  mergeTerms,
};
