// External Imports
import { LOCATION_CHANGE } from 'connected-react-router';
import get from 'lodash/fp/get.js';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';

// Internal Imports
import { actionReceiveError, actionReceiveSecondaryContent } from '../actions/index.mjs';
import { contentRelationMap } from '../config/types/content/relations.mjs';
import { RECEIVE_PRIMARY_CONTENT } from '../config/types/action.mjs';
import { CONTENT_ITEM_SINGLE } from '../config/types/path.mjs';
import { createSelectContentItemBySlug } from '../selectors/createSelectContentItem.mjs';
import { selectMatch } from '../selectors/selectMatch.mjs';
import { selectHasFetchedActiveContent } from '../selectors/selectHasFetchedActiveContent.mjs';
import { reportWordPressFetchingError } from '../utils/rollbar.mjs';

// Local Functions
function* contentItemMetaSaga(service) {
  yield takeLatest(LOCATION_CHANGE, watchForContentItem, service);
}

/**
 * Emit side effects related to page metadata.
 * @param {function} fetchContentItemsByIds
 */
function* watchForContentItem({ fetchContentItemsByIds }) {
  const match = yield select(selectMatch);
  if (!match) {
    return;
  }

  const { params, pathType } = match;

  if (pathType !== CONTENT_ITEM_SINGLE) {
    return;
  }

  if (!(yield select(selectHasFetchedActiveContent))) {
    yield take(RECEIVE_PRIMARY_CONTENT);
  }

  const { contentType } = params;
  const selectContentItemBySlug = createSelectContentItemBySlug(contentType);
  const item = yield select((state) => selectContentItemBySlug(state, { match }));
  if (!item) {
    return;
  }

  const relations = contentRelationMap[contentType] || contentRelationMap[pathType];
  if (!relations) {
    return;
  }

  const isLazyLoaded = (path) => !relations[path].eager;
  const effects = Object.keys(relations)
    .filter((relation) => isLazyLoaded(relation))
    .map((path) => {
      const relation = relations[path];
      const ids = get(`metadata.${path}`, item);
      return call(fetchContentItemsByIds, ids, relation.contentType);
    });

  if (effects.length === 0) {
    return;
  }

  try {
    const results = yield all(effects);
    let content = {};
    for (const result of results) {
      content = { ...content, ...result };
    }
    yield put(actionReceiveSecondaryContent(content));
  } catch (error) {
    reportWordPressFetchingError('Error when fetching contentItemMeta', error);
    yield put(actionReceiveError(error));
  }
}

// Module Exports
export { contentItemMetaSaga, watchForContentItem };
