// Internal Imports
import { lazyApiClient } from './apiClient.mjs';

// Local Functions
const authenticatedRequest = async ({ ...fetchArguments }) => {
  const apiClient = await lazyApiClient();
  await apiClient.authenticate();
  return apiClient.fetch({
    ...fetchArguments,
  });
};

// Module Exports
export { authenticatedRequest };
