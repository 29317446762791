// External Imports
import compose from 'lodash/fp/flow.js';
import keyBy from 'lodash/fp/keyBy.js';
import mapValues from 'lodash/fp/mapValues.js';
import merge from 'lodash/fp/merge.js';
import pick from 'lodash/fp/pick.js';

// Internal Imports
import { contentGroup as defaultState } from '../config/defaultState.mjs';
import * as contentTypes from '../config/types/content/index.mjs';
import {
  RECEIVE_PRIMARY_CONTENT,
  RECEIVE_SECONDARY_CONTENT,
  RECEIVE_SITE_OPTIONS,
} from '../config/types/action.mjs';
import { filterContentFields } from '../utils/scriptFilter.mjs';

// Local Functions
const isContentType = (type) => Object.values(contentTypes).includes(type);

const postProcessContentEntities = (entities) => ({
  ...entities,
  postProcessedData: mapValues(filterContentFields)(entities.byId),
});

// For documentation on this see docs/article-content-embeds.md
const postProcessContent = (contentGroupState) => {
  const postProcessedContentGroupState = {};
  for (const [type, entities] of Object.entries(contentGroupState)) {
    postProcessedContentGroupState[type] = isContentType(type)
      ? postProcessContentEntities(entities)
      : entities;
  }
  return postProcessedContentGroupState;
};

/**
 * Create a state slice reducer that will set the initial state shape based on
 * the "content group" types (taxonomies and content types) returned from
 * RECEIVE_SITE_OPTIONS. Child types related to their parent group type will
 * be merged into the state on receive content actions.
 *
 * @param {string}     groupType  group type from RECEIVE_SITE_OPTIONS payload
 * @returns {function}            Redux reducer function
 */
const createContentGroupReducer =
  (groupType) =>
  (state = {}, { type, payload } = {}) => {
    switch (type) {
      case RECEIVE_SITE_OPTIONS: {
        const templateTypes = payload[groupType];
        const allTypesWithDefaultContentState = {};
        for (const templateType of templateTypes) {
          allTypesWithDefaultContentState[templateType.id] = defaultState;
        }
        return allTypesWithDefaultContentState;
      }

      case RECEIVE_PRIMARY_CONTENT:
      case RECEIVE_SECONDARY_CONTENT: {
        const types = Object.keys(state);
        const indexBySlug = compose(keyBy('slug'), mapValues('id'));

        const normalize = (entities) => ({
          byId: keyBy('id', entities),
          idBySlug: indexBySlug(entities),
        });

        const mergeTypes = compose(
          pick(types),
          mapValues(normalize),
          postProcessContent,
          merge(state),
        );

        return mergeTypes(payload);
      }

      default:
    }

    return state;
  };

// Module Exports
export { createContentGroupReducer };
