// External Imports
import { createSelector } from 'reselect';

// Local Functions
const selectUserState = (state) => state.user;

const isRemoteDataInitialized = (isLoading, hasErrored, remoteData) =>
  !isLoading && (hasErrored || remoteData !== undefined);

const selectIsLoading = createSelector(selectUserState, (userState) => userState.isLoading);

const selectHasErrored = createSelector(
  selectUserState,
  (userState) => undefined !== userState.error,
);

const selectRemoteData = createSelector(
  [selectUserState, selectHasErrored],
  (userState, hasErrored) => (hasErrored ? undefined : userState.remoteData),
);

const selectAuthentication = createSelector(
  [selectUserState],
  ({ authentication }) => authentication,
);

const selectAuthenticationIsLoading = createSelector(
  selectAuthentication,
  (userState) => userState.isLoading,
);

const selectAuthenticationHasErrored = createSelector(
  selectAuthentication,
  (userState) => undefined !== userState.error,
);

const selectAuthenticationRemoteData = createSelector(
  [selectAuthentication, selectAuthenticationHasErrored],
  (userState, hasErrored) => (hasErrored ? undefined : userState.remoteData),
);

const selectAuthenticationIsInitialized = createSelector(
  [selectAuthenticationIsLoading, selectAuthenticationHasErrored, selectAuthenticationRemoteData],
  isRemoteDataInitialized,
);

const selectIsInitialized = createSelector(
  [selectIsLoading, selectHasErrored, selectRemoteData],
  isRemoteDataInitialized,
);

const selectUUID = createSelector(selectRemoteData, (remoteData) => remoteData?.uuid);

const selectIsLoggedIn = createSelector(selectUUID, (uuid) => undefined !== uuid);

// Module Exports
export {
  selectAuthenticationHasErrored,
  selectAuthenticationIsInitialized,
  selectAuthenticationIsLoading,
  selectHasErrored,
  selectIsInitialized,
  selectIsLoading,
  selectIsLoggedIn,
  selectUUID,
};
