import { CONTENT_ITEM_SINGLE } from './path.mjs';

const COAUTHORS_RELATED_POSTS = 'with_coauthors_related_posts';
const RELATED_POSTS = 'with_related_posts';

const pathTypeParameterMap = {
  [CONTENT_ITEM_SINGLE]: {
    [COAUTHORS_RELATED_POSTS]: true,
    [RELATED_POSTS]: true,
  },
};

// Module Exports
export { COAUTHORS_RELATED_POSTS, pathTypeParameterMap, RELATED_POSTS };
