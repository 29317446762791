// This must always happen first as it will allow webpack to resolve chunks correctly
import './utils/setWebpackPublicPathFromSsrInjectedPath.mjs';

// External Imports
import { ServerBabbelThemeProvider } from '@lessonnine/design-system.lib';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import debug from 'debug';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
// eslint-disable-next-line import/no-extraneous-dependencies -- should be limited to development only according to the documented /developmentOnly flag
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';

// Internal Imports
import { App } from './components/App/App.jsx';
import { ScrollToTop } from './components/Nav/ScrollToTop.jsx';
import { defaultState } from './config/defaultState.mjs';
import { trackingMiddleware } from './middlewares/tracking.mjs';
import { createRootReducer } from './reducers/index.mjs';
import { requestSiteOptions, rootSaga } from './sagas/index.mjs';
import { locateRemoteService } from './services/locateRemoteService.mjs';
import { createHistoryWithInProductQueryParameter } from './utils/createHistoryWithInProductQueryParameter.mjs';

if (process.env.REACT_APP_DEBUG) {
  debug.enable(process.env.REACT_APP_DEBUG);
}

// Local Variables
const history = createHistoryWithInProductQueryParameter(createBrowserHistory());

const canHotReload = process.env.NODE_ENV !== 'production' && import.meta.webpackHot;
const sagaMiddleware = createSagaMiddleware();

const enhancer = composeWithDevTools(
  applyMiddleware(routerMiddleware(history), sagaMiddleware, trackingMiddleware),
);

const state = window.__PRELOADED_STATE__ || defaultState; // eslint-disable-line no-underscore-dangle -- standard for preloaded variables inserted into window
const store = createStore(createRootReducer(history), state, enhancer);

// Local Functions
async function renderApp() {
  const service = await locateRemoteService();
  const rootElement = document.querySelector('#root');

  // This will technically block the app from rendering which may cause a FOUC
  // UX, but the time to execute this will be minimal if the app was pre-rendered
  // on the server, because the site options will already be available.
  try {
    // The app depends on site options being available in the state container.
    await sagaMiddleware.run(requestSiteOptions, service).toPromise();
  } catch {
    // If an error has occurred then some critical system has failed, and it
    // is impossible for the app to render or function normally.
    // TODO: Show a more user friendly error message
    ReactDOM.render(<p>Please try again later.</p>, rootElement);

    return;
  }

  sagaMiddleware.run(rootSaga, service);

  if (canHotReload) {
    import.meta.webpackHot.accept('./reducers', () =>
      store.replaceReducer(createRootReducer(history)),
    );
  }

  const renderOrHydrate = canHotReload ? ReactDOM.render : ReactDOM.hydrate;

  renderOrHydrate(
    <ServerBabbelThemeProvider defaultMode="system">
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ScrollToTop>
            <App />
          </ScrollToTop>
        </ConnectedRouter>
      </Provider>
    </ServerBabbelThemeProvider>,
    rootElement,
  );

  if (canHotReload) {
    import.meta.webpackHot.accept('./components/App/App.jsx', async () => {
      const { App: NextApp } = await import('./components/App/App.jsx');
      ReactDOM.render(
        <ServerBabbelThemeProvider defaultMode="system">
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <NextApp />
            </ConnectedRouter>
          </Provider>
        </ServerBabbelThemeProvider>,
        rootElement,
      );
    });
  }
}

// eslint-disable-next-line unicorn/prefer-top-level-await -- TODO: top level await not supported yet by Webpack (available from 5.83)
renderApp();
