// External Imports
import { compile } from 'path-to-regexp';
import { connect } from 'react-redux';
import get from 'lodash/fp/get';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  styled,
  pxToRem,
  Stack,
  Text,
  Layout,
  Header,
} from '@lessonnine/design-system.lib';
import tokens from '@lessonnine/design-tokens.lib/dist/json/variables.json';

// Internal Imports
import { buildCTALink, buildHomepageLink } from '../../utils/url.mjs';
import { checkUsEnglish } from '../../utils/checkUsEnglish.mjs';
import { EditionPicker } from '../EditionPicker/EditionPicker.jsx';
import { FooterNav } from './FooterNav.jsx';
import { selectEdition } from '../../selectors/selectEdition.mjs';
import { selectIs404 } from '../../selectors/selectIs404.mjs';
import { selectMatch } from '../../selectors/selectMatch.mjs';
import { selectMenuTopLevel } from '../../selectors/selectMenu.mjs';
import { selectPageTrackingData } from '../../selectors/selectPageTrackingData.mjs';
import { selectPathMap } from '../../selectors/selectPathMap.mjs';
import { I18n } from '../I18n/I18n.jsx';
import { LANGUAGE_EDITION, CONTENT_ITEM_SINGLE } from '../../config/types/path.mjs';
import { MENU, LANGUAGES } from '../../config/types/prop.mjs';
import { options } from '../../config/reactRedux.mjs';
import { pageType, trackPageEvent } from '../../utils/tracking.mjs';
import { SocialLinks } from '../SocialLinks/SocialLinks.jsx';
import { useTrackPageCompleted } from '../../hooks/useTrackPageCompleted/index.mjs';
import {
  FOOTER,
  FOOTER_COLUMN_1,
  FOOTER_COLUMN_2,
  FOOTER_COLUMN_3,
  FOOTER_COPYRIGHT,
} from '../../config/types/menu.mjs';
import { siteMaxWidth } from '../../config/css/ui.mjs';
import { Separator } from '../Separator/Separator.jsx';

const FooterStack = styled(Stack)`
  border-top: 1px solid ${({ theme }) => theme.color.surface.layer.border};
`;

const LayoutWithMaxWidth = styled(Layout)`
  max-width: ${pxToRem(siteMaxWidth)};
  width: 100%;
`;

const AltLinkContainer = styled.div`
  display: inline-block;
  min-width: ${pxToRem(254)};
  position: relative;
`;

const FlexNav = styled.nav`
  flex: 1 1 auto;
`;

const FooterBottomNav = styled.nav`
  margin: 0 auto;
  width: 100%;
`;

const sectionsGap = 60;
const sectionsPadding = [0, 15];

// Component Definition
function Footer({
  copyright,
  columnOne,
  columnTwo,
  columnThree,
  currentEdition,
  editions,
  currentLanguage,
  is404,
  pageTrackingData,
}) {
  const { data: trackingData } = pageTrackingData;
  const footerElement = useTrackPageCompleted({
    is404,
    trackingData,
    trackingEnabled: trackingData?.page_type !== pageType[CONTENT_ITEM_SINGLE],
  });
  const goLink = buildCTALink(currentEdition);
  const ctaLabel = <I18n field="try-babbel" defaultText="Try Babbel" />;
  const ctaHeading = <I18n field="learn-a-new-language" defaultText="Learn a new language" />;
  const trackFooterEvent = () => {
    trackPageEvent({
      cta_placement: 'footer',
      link_url: goLink,
      name: 'magazine_page:cta_clicked',
      version: 2,
      ...trackingData,
    });
  };

  return (
    <footer ref={footerElement}>
      <FooterStack alignItems="center" gap={sectionsGap} padding={[70, 0]}>
        <LayoutWithMaxWidth gap={sectionsGap} direction="stack" padding={sectionsPadding}>
          <EditionPicker editions={editions} currentEdition={currentEdition} />
          <Stack gap={tokens.size.space.medium}>
            {/* eslint-disable-next-line react/jsx-pascal-case -- design-system's interface */}
            <Header.h2>
              <I18n field="footer-title" defaultText="More Babbel" />
            </Header.h2>
            <Layout align="leading" direction={{ small: 'inline', xsmall: 'stack' }} gap={40}>
              <FlexNav aria-label="About Babbel:">
                <FooterNav
                  headingTranslation="about-babbel"
                  heading="About Babbel:"
                  items={columnOne}
                  currentLanguage={currentLanguage}
                />
              </FlexNav>
              <FlexNav aria-label="Learn Languages Online:">
                <FooterNav
                  headingTranslation="learn-languages-online"
                  heading="Learn Languages Online:"
                  items={columnTwo}
                  currentLanguage={currentLanguage}
                />
              </FlexNav>
              <FlexNav aria-label="This page in other languages:">
                <FooterNav
                  headingTranslation="this-page-in-other-languages"
                  heading="This page in other languages:"
                  items={columnThree}
                  currentLanguage={currentLanguage}
                />
              </FlexNav>
              <Stack gap={15}>
                <Text fontSize="medium" fontWeight="600" letterSpacing="0.06">
                  {ctaHeading}
                </Text>
                <AltLinkContainer>
                  <Button
                    fullWidth
                    as="a"
                    href={goLink}
                    size="jumbo"
                    onClick={() => trackFooterEvent()}
                  >
                    {ctaLabel}
                  </Button>
                </AltLinkContainer>
              </Stack>
            </Layout>
          </Stack>
        </LayoutWithMaxWidth>
        <Separator />
        <LayoutWithMaxWidth
          gap={tokens.size.space.medium}
          alignItems="center"
          direction={{ medium: 'inline', small: 'stackReverse' }}
          padding={sectionsPadding}
        >
          {/* TODO: add translations for the aria label text */}
          <FooterBottomNav aria-label="Copyright">
            <FooterNav copyright currentLanguage={currentLanguage} items={copyright} />
          </FooterBottomNav>
          <SocialLinks />
        </LayoutWithMaxWidth>
      </FooterStack>
    </footer>
  );
}

Footer.propTypes = {
  columnOne: PropTypes.arrayOf(PropTypes.shape(MENU)).isRequired,
  columnThree: PropTypes.arrayOf(PropTypes.shape(MENU)).isRequired,
  columnTwo: PropTypes.arrayOf(PropTypes.shape(MENU)).isRequired,
  copyright: PropTypes.arrayOf(PropTypes.shape(MENU)).isRequired,
  currentEdition: LANGUAGES.isRequired,
  currentLanguage: LANGUAGES.isRequired,
  editions: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types -- dynamically built
  is404: PropTypes.bool.isRequired,
  pageTrackingData: PropTypes.shape({
    data: PropTypes.object, // eslint-disable-line react/forbid-prop-types -- dynamically built
    isLoading: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => {
  const { params } = selectMatch(state);
  const pathMap = selectPathMap(state);
  // This could be turned into a selector if we ever need to reuse it
  const toLanguagePath = compile(pathMap[LANGUAGE_EDITION]);
  const editionNames = get('languages.translations', state);
  const currentEdition = selectEdition(state);
  const pageTrackingData = selectPageTrackingData(state);
  let editions = {};

  if (editionNames) {
    const editionsReducer = (accumulator, languageCode) => {
      const path = toLanguagePath({
        ...params,
        language: checkUsEnglish(languageCode),
      });

      const link = buildHomepageLink(languageCode, path);

      return {
        ...accumulator,
        [languageCode]: {
          link,
          name: editionNames[languageCode],
        },
      };
    };
    // eslint-disable-next-line unicorn/no-array-reduce -- TODO: eliminate this use of array.prototype.reduce()
    editions = Object.keys(editionNames).reduce(
      (accumulator, element) => editionsReducer(accumulator, element),
      {},
    );
  }

  return {
    columnOne: selectMenuTopLevel(state, FOOTER_COLUMN_1),
    columnThree: selectMenuTopLevel(state, FOOTER_COLUMN_3),
    columnTwo: selectMenuTopLevel(state, FOOTER_COLUMN_2),
    copyright: selectMenuTopLevel(state, FOOTER_COPYRIGHT),
    currentEdition,
    currentLanguage: params.language,
    editions,
    footer: selectMenuTopLevel(state, FOOTER),
    is404: selectIs404(state),
    pageTrackingData,
  };
};

const withRedux = connect(mapStateToProps, undefined, undefined, options);
const FooterHoc = withRedux(Footer);

// Module Exports
export { FooterHoc as Footer };
