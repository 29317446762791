// External Imports
import { LOCATION_CHANGE, replace } from 'connected-react-router';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';

// Internal Imports
import {
  actionReceivePrimaryContent,
  actionReceiveError,
  actionReceiveSiteOptions,
  actionReceiveSiteOptionsError,
  actionRequestArchive,
  actionRequestContentItem,
  actionRequestSiteOptions,
  actionRequestNextPageFetchInitiated,
} from '../actions/index.mjs';
import { REQUEST_NEXT_PAGE, FETCH_USER_REQUESTED } from '../config/types/action.mjs';
import { selectActivePagination } from '../selectors/selectActivePagination.mjs';
import { selectMatch } from '../selectors/selectMatch.mjs';
import { selectRedirect } from '../selectors/selectRedirect.mjs';
import { selectHasFetchedActiveContent } from '../selectors/selectHasFetchedActiveContent.mjs';
import { selectContentGroupTypes } from '../selectors/selectContentGroupTypes.mjs';
import { logger, reportWordPressFetchingError } from '../utils/rollbar.mjs';
import { contentItemMetaSaga } from './contentItemMetaSaga.mjs';
import { fetchUser } from './userSaga.mjs';
import { selectIsRoute404 } from '../selectors/selectIs404.mjs';

// Local Functions
/**
 * Dynamic saga to be run after Redux store creation.
 * @param {Object} service remote service implementation
 */
function* rootSaga(service) {
  try {
    // Combine all continuous sagas, and run them in parallel.
    yield all([
      takeLatest(LOCATION_CHANGE, watchLocationChanges, service),
      takeLatest(REQUEST_NEXT_PAGE, watchRequestNextPage, service),
      takeLatest(FETCH_USER_REQUESTED, fetchUser, service),
      fork(contentItemMetaSaga, service),
    ]);
  } catch (error) {
    yield put(actionReceiveError(error));
    logger.error('Unexpected error happened in a redux saga.', error);
  }
}

/**
 * Emit site options and related side effects.
 * @param {Object} service remote service implementation
 */
function* requestSiteOptions(service) {
  let contentGroupTypes = yield select(selectContentGroupTypes);
  const hasGroupTypes = contentGroupTypes.every((group) => group.length);
  if (!hasGroupTypes) {
    yield put(actionRequestSiteOptions());
    try {
      const siteOptions = yield call(service.fetchSiteOptions);
      yield put(actionReceiveSiteOptions(siteOptions));
    } catch (error) {
      reportWordPressFetchingError('Error fetching site options', error);
      yield put(actionReceiveSiteOptionsError(error));
      return;
    }
  }

  // Make sure the service state has been updated with content group types
  // from site options. If the app was pre-rendered on the server, then the
  // service will be lacking content group types.
  contentGroupTypes = yield select(selectContentGroupTypes);
  yield call(service.registerContentGroups, ...contentGroupTypes);
}

/**
 * Emit location change side effects.
 * @param {Object} service remote service implementation
 */
function* watchLocationChanges(service) {
  if (yield select(selectIsRoute404)) {
    return;
  }
  const { fetchArchive, fetchContentItem } = service;
  const match = yield select(selectMatch);
  const { isArchive } = match;

  const redirect = yield select(selectRedirect);

  if (redirect) {
    yield put(replace(redirect));
    return;
  }

  if (yield select(selectHasFetchedActiveContent)) {
    return;
  }

  if (isArchive) {
    const { perPage, currentPage } = yield select(selectActivePagination);
    yield put(actionRequestArchive());
    try {
      const content = yield call(fetchArchive, match, perPage, currentPage);
      if (content) {
        content.appSpecificMeta = { hasLocationChanged: true };
      }
      yield put(actionReceivePrimaryContent(content));
    } catch (error) {
      reportWordPressFetchingError('Error when fetching archive', error);
      yield put(actionReceiveError(error));
      // eslint-disable-next-line no-useless-return -- safer to have return here if we have more code after the if else later
      return;
    }
  } else {
    yield put(actionRequestContentItem());
    try {
      const content = yield call(fetchContentItem, match);
      if (content) {
        content.appSpecificMeta = { hasLocationChanged: true };
      }
      yield put(actionReceivePrimaryContent(content));
    } catch (error) {
      reportWordPressFetchingError('Error when fetching content item', error);
      yield put(actionReceiveError(error));
      // eslint-disable-next-line no-useless-return -- safer to have return here if we have more code after the if else later
      return;
    }
  }
}

/**
 * Emit request next page side effects.
 * @param {function} fetchArchive
 */
function* watchRequestNextPage(service) {
  const { fetchArchive } = service;
  const match = yield select(selectMatch);
  const { isArchive } = match;
  if (!isArchive) {
    return;
  }

  if (yield select(selectHasFetchedActiveContent)) {
    return;
  }

  yield put(actionRequestNextPageFetchInitiated());

  const { perPage, currentPage } = yield select(selectActivePagination);
  try {
    const content = yield call(fetchArchive, match, perPage, currentPage);
    if (content) {
      content.appSpecificMeta = { hasLocationChanged: false };
    }
    yield put(actionReceivePrimaryContent(content));
  } catch (error) {
    reportWordPressFetchingError('Error when fetching archive next page', error);
    yield put(actionReceiveError(error));
  }
}

// Module Exports
export { requestSiteOptions, rootSaga, watchLocationChanges, watchRequestNextPage };
