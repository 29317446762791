// External Imports
import { createSelector } from 'reselect';

// Internal Imports
import { isNode } from '../utils/environment.mjs';
import { selectAuthenticationIsInitialized } from './selectUser.mjs';
import { safeGetCookie } from '../utils/cookie.mjs';

// Local Functions
const selectIsBabbelTrackingUuidSet = createSelector(
  // the cookie’s value should change after the authentication of the user
  // as the endpoint should set the tracking uuid if it isn’t already set.
  // Given selectors are memoized, we will force reselect to re-evaluate the
  // cookie’s value whenever selectAuthenticationIsInitialized’s value changes.
  [selectAuthenticationIsInitialized],
  () => !isNode() && safeGetCookie().includes('babbeltrackinguuid='),
);

// Module Exports
export { selectIsBabbelTrackingUuidSet };
