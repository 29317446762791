// External Imports
import WPAPI from 'wpapi';
import defaultsDeep from 'lodash/fp/defaultsDeep.js';
import merge from 'lodash/fp/merge.js';
import intersection from 'lodash/fp/intersection.js';
import values from 'lodash/fp/values.js';

// Internal Imports
import { searchTypes, POST } from '../../config/types/content/index.mjs';
import {
  HOME,
  CONTENT_ITEM_SINGLE,
  TERM_ARCHIVE,
  AUTHOR_ARCHIVE,
  SEARCH_ARCHIVE,
  CATEGORY_ARCHIVE,
} from '../../config/types/path.mjs';
import { pathTypeParameterMap } from '../../config/types/parameter.mjs';
import * as taxonomyTypes from '../../config/types/taxonomy.mjs';
import { ForbiddenError } from '../../errors/forbiddenError.mjs';
import { createDebug } from '../../utils/createDebug.mjs';
import {
  createContentPayload,
  createSiteOptionsPayload,
  makeRequestWithRetry,
  mergeTerms,
} from './util.mjs';
import { TimeoutError } from '../../errors/timeoutError.mjs';

// Local Functions
const debugWpapiRequest = createDebug('wpapi:request');

const applyPathTypeParameters = (handler, match) => {
  const pathTypeParameters = pathTypeParameterMap[match.pathType];

  if (pathTypeParameters) {
    for (const parameterName of Object.keys(pathTypeParameters)) {
      handler.param(parameterName, pathTypeParameters[parameterName]);
    }
  }

  return handler;
};

const getTermHandlerName = (taxonomy) => {
  switch (taxonomy) {
    case taxonomyTypes.CATEGORY:
      return 'categories';

    case taxonomyTypes.TAG:
      return 'tags';

    default:
      return taxonomy;
  }
};

const applyArchiveArguments = (handler, query, perPage, page) => {
  handler.perPage(perPage).page(page).embed();

  if (!query.order || query.order === 'relevance') {
    handler.orderby('relevance');
  } else {
    handler.order(query.order);
  }

  const taxonomies = intersection(values(taxonomyTypes), Object.keys(query));
  for (const taxonomy of taxonomies) {
    handler[taxonomy](query[taxonomy]);
  }
  return handler;
};

// Local Classes
class WordPressRestApiService {
  /**
   * Mixin a query param to a node-wpapi end point resource handler.
   * @param {Object} handler EndpointResource
   * @param {String} param   param name
   */
  static mixinParam(handler, parameter) {
    // eslint-disable-next-line no-param-reassign -- TODO: assess if we could do the job differently
    handler[parameter] = function setParameterValue(value) {
      return this.param(parameter, value);
    }.bind(handler);
  }

  static checkError(error) {
    // If post type is not available in API, handle as 404.
    if (error.code && error.code === 'rest_no_route') {
      return;
    }

    // Attempted to preview a post with invalid credentials.
    if (['incorrect_password', 'invalid_username'].includes(error.code)) {
      throw new ForbiddenError(error.message);
    }

    throw error;
  }

  options = {
    multiple: 'multiple-post-type',
    options: '/options',
    pluginRoot: 'alley-react/v1',
    preview: '/preview/(?P<id>)',
    root: 'https://staging.cms.babbel.news/wp-json',
  };

  taxonomies = [];

  /**
   * Construct a WordPress API service that fulfills the implicit remote service API
   * @param {string} overrides.root        root url of WPAPI instance
   * @param {string} overrides.pluginRoot  root path of the WPAPI custom plugin
   * @param {string} overrides.options     path to options endpoint
   * @returns {object.<function>}          service object
   */
  constructor(overrides) {
    this.options = merge(this.options, overrides);

    const { pluginRoot, options, preview, root, multiple, username, password } = this.options;
    this.wp = new WPAPI({
      endpoint: root,
      password,
      transport: {
        get(wpreq, callback) {
          const url = wpreq.toString();
          debugWpapiRequest(`WPAPI request URL: ${url}`);

          // Module "wpapi" does not natively support timeout, so add it manually with
          // Promise.race() to prevent the event loop from filling up if API requests start taking
          // a long time
          //
          // NOTE: the request will continue until completion after timeout because there's no way
          //       to access the request object and cancel it directly; at least this frees the
          //       event loop to continue processing
          // Timeout is setup as 13_000, the idea is we generally have two network requests per page, and a timeout on the lambda of 30s, so leaving 13s should allow for both calls to make it and the lambda to process correctly, making this higher is dangerous as the Lambda might timeout if we go too high
          const timeoutDuration = 13_000;
          let timeoutID;
          const timeoutPromise = new Promise((resolve, reject) => {
            timeoutID = setTimeout(reject, timeoutDuration);
          }).catch(() => {
            const error = new TimeoutError(
              `API request to URL "${url}" timed out after ${timeoutDuration}ms`,
            );
            throw error;
          });

          const apiRequestPromise = WPAPI.transport.get(wpreq, callback).then((data) => {
            clearTimeout(timeoutID);
            return data;
          });

          return Promise.race([apiRequestPromise, timeoutPromise]);
        },
      },
      username,
    });

    this.wp.options = this.wp.registerRoute(pluginRoot, options);
    this.wp.preview = this.wp.registerRoute(pluginRoot, preview);

    // TODO: Use multiple post type end point as much as possible, as it makes most of WPAPI obsolete.
    this.wp.multiple = this.wp.registerRoute('wp/v2', multiple, {
      params: ['type', 'categories', 'tags', 'search', 'author'],
    });

    this.wp.author = this.wp.registerRoute('babbel/v1', '/author/(?P<authorSlug>)');

    // Bindings
    this.fetchArchive = this.fetchArchive.bind(this);
  }

  /**
   * Execute http request to resolve site options.
   * Apply custom routes to wp object.
   * @returns {Promise}
   */
  fetchSiteOptions = async () => {
    const response = await makeRequestWithRetry(() => this.wp.options().get());

    this.registerContentGroups(response.postTypes, response.taxonomies);

    return createSiteOptionsPayload(response);
  };

  /**
   * Execute http request to resolve a content item by route match slug.
   * @param {object} match route match
   * @returns {Promise}    promise that returns a content item action payload
   */
  fetchContentItem = async (match) => {
    let handler = this.getHandlerByMatch(match);
    let response = [];

    handler = applyPathTypeParameters(handler, match);

    try {
      response = await makeRequestWithRetry(() => handler.slug(match.params.slug).embed().get());
    } catch (error) {
      WordPressRestApiService.checkError(error);
    }

    // If nested page, there may be multiple items with equal slugs.
    if (response.length > 1) {
      // Match on entire url path to find correct item.
      response = response.filter(({ link }) => link.includes(match.url));
      // Shim expected _paging property.
      // Response is an array, so adding a property is weird, but this
      // is the behavior of node-wpapi that we rely on and emulate here.
      response._paging = { total: 1, totalPages: 1 }; // eslint-disable-line no-underscore-dangle -- Wordpress API shape
    }

    // If res is still empty, return early
    if (response.length === 0) {
      return false;
    }

    return createContentPayload(response, undefined, match);
  };

  /**
   * Execute http request to resolve a content archive by route match.
   * @param {object} match   route match
   * @param {number} perPage number of entities per page
   * @param {number} page    page number
   * @returns {Promise}      promise that resolves a content action payload
   */
  async fetchArchive(match, perPage, page) {
    const { pathType, query } = match ?? {};
    let response = [];

    try {
      switch (pathType) {
        case TERM_ARCHIVE:
        case CATEGORY_ARCHIVE:
          return this.fetchTermArchive(match, perPage, page);

        case AUTHOR_ARCHIVE:
          response = await this.fetchAuthorArchive(match, perPage, page);
          break;

        default: {
          const handler = this.getHandlerByMatch(match);
          response = await applyArchiveArguments(handler, query, perPage, page);
        }
      }
    } catch (error) {
      WordPressRestApiService.checkError(error);
    }

    return createContentPayload(response, page, match);
  }

  fetchTermArchive = async (match, perPage, page) => {
    const {
      params: { taxonomy, slug },
    } = match;
    const handler = this.getHandlerByMatch(match);
    const termHandlerName = getTermHandlerName(taxonomy);
    // Terms requested directly will include the "full" term object shape.
    const terms = await this.fetchTerms(termHandlerName, slug);

    // Apply pagination to query.
    handler.perPage(perPage).page(page).embed();

    const response = await handler[termHandlerName](terms.map(({ id }) => id));
    const payload = createContentPayload(response, page, match);
    return mergeTerms(payload, taxonomy, terms);
  };

  fetchAuthorArchive = async (match, perPage, page) => {
    const {
      params: { slug },
      query,
    } = match;
    const handler = this.wp.author().authorSlug(slug);
    const response = await applyArchiveArguments(handler, query, perPage, page);
    const { posts } = response;
    const { _paging: paging } = posts;
    // Monkey patch pagination fields.
    posts._paging = paging; // eslint-disable-line no-underscore-dangle -- Wordpress API shape
    return posts;
  };

  fetchTerms = (termHandlerName, slug) => this.wp[termHandlerName]().slug(slug);

  fetchAuthor = (slug) => makeRequestWithRetry(() => this.wp.users().slug(slug).get());

  fetchPreviewItem = async (match, id) => {
    let response = [];
    try {
      response = await makeRequestWithRetry(() => this.wp.preview().id(id).embed().get());
    } catch (error) {
      WordPressRestApiService.checkError(error);
    }

    if (response.length === 0) {
      return response;
    }

    // Shim expected _paging property.
    // eslint-disable-next-line no-underscore-dangle -- Wordpress API shape
    if (!response._paging) {
      // eslint-disable-next-line no-underscore-dangle -- Wordpress API shape
      response._paging = { total: 1, totalPages: 1 };
    }

    // Ensure response has expected related entity structure.
    const setDefaults = defaultsDeep({
      _embedded: {
        author: {},
        'wp:term': [],
      },
    });

    // Use slug derived from url in all cases. The draft post may not have
    // have enough data to generate the correct slug. Since we fetch by id,
    // having the correct slug isn't important, but the slug needs to match
    // within the app to be correctly selected when rendering.
    response[0] = { ...setDefaults(response[0]), slug: match.params.slug };

    return createContentPayload(response);
  };

  registerContentGroups = (contentTypes, taxonomies) => {
    const registerRoute = (type) => {
      if (this.wp[type]) {
        return;
      }

      this.wp[type] = this.wp.registerRoute('wp/v2', `/${type}/(?P<id>)`);
    };

    for (const taxonomy of [...contentTypes, ...taxonomies]) {
      registerRoute(taxonomy);
    }
    this.taxonomies = taxonomies;
  };

  getSearchHandler = (search, types = searchTypes) =>
    this.getMultipleHandler().type(types).search(search);

  /**
   * @param {object}   match react-router route match
   * @returns {object}       node-wpapi handler
   */
  getHandlerByMatch = (match) => {
    if (!match) {
      // eslint-disable-next-line unicorn/no-null -- optional return, alternative with undefined breaks more rules
      return null;
    }

    const { params, pathType, query } = match;
    switch (pathType) {
      case CONTENT_ITEM_SINGLE:
        // This logic is necessary because the content type is `post` but the
        // endpoint is `posts` (plural), unlike every other content type.
        return POST === params.contentType ? this.wp.posts() : this.wp[params.contentType]();

      case HOME:
        return this.wp.pages();

      case AUTHOR_ARCHIVE:
      case CATEGORY_ARCHIVE:
      case TERM_ARCHIVE: {
        const { contentType } = params;
        const types = contentType ? [contentType] : searchTypes;
        return this.getMultipleHandler().type(types);
      }

      case SEARCH_ARCHIVE:
        return this.getSearchHandler(query.s, query.types);

      default:
        // eslint-disable-next-line unicorn/no-null -- optional return, alternative with undefined breaks more rules
        return null;
    }
  };

  getMultipleHandler = () => {
    const handler = this.wp.multiple();

    for (const taxonomy of this.taxonomies) {
      WordPressRestApiService.mixinParam(handler, taxonomy);
    }

    return handler;
  };
}

// Module Exports
export { applyArchiveArguments, WordPressRestApiService };
