import React from 'react';
import Helmet from 'react-helmet';
import moment from 'moment';
import PropTypes from 'prop-types';

function BlogPostingStructuredData({
  articleUrl,
  title,
  articleDescription,
  articlePublishDate,
  articleWordCount,
  featuredImageUrl,
  featuredImageWidth,
  featuredImageHeight,
  authorUrl,
  authorName,
  authorImageUrl,
  authorImageWidth,
  authorImageHeight,
  currentEdition,
}) {
  const formattedArticlePublishDate = moment(articlePublishDate).format('YYYY-MM-DD');
  const structuredData = {
    '@context': 'https://schema.org/',
    '@id': `${articleUrl}#BlogPosting`,
    '@type': 'BlogPosting',
    author: {
      '@id': `${authorUrl}#Person`,
      '@type': 'Person',
      image: {
        '@id': authorImageUrl,
        '@type': 'ImageObject',
        height: authorImageHeight,
        url: authorImageUrl,
        width: authorImageWidth,
      },
      name: authorName,
      url: authorUrl,
    },
    datePublished: formattedArticlePublishDate,
    description: articleDescription,
    headline: title,
    image: {
      '@id': featuredImageUrl,
      '@type': 'ImageObject',
      height: featuredImageHeight,
      url: featuredImageUrl,
      width: featuredImageWidth,
    },
    isPartOf: {
      '@id': `https://www.babbel.com/${currentEdition}/magazine`,
      '@type': 'Blog',
      name: 'Babbel Magazine',
      publisher: {
        '@id': 'https://www.babbel.com',
        '@type': 'Organization',
        name: 'Babbel',
      },
    },
    mainEntityOfPage: articleUrl,
    name: title,
    publisher: {
      '@id': 'https://www.babbel.com',
      '@type': 'Organization',
      logo: {
        '@id': 'https://www.babbel.com/static/layout/logo-for-knowledge-graph-square.png',
        '@type': 'ImageObject',
        height: 512,
        url: 'https://www.babbel.com/static/layout/logo-for-knowledge-graph-square.png',
        width: 512,
      },
      name: 'Babbel',
    },
    url: articleUrl,
    wordCount: articleWordCount,
  };
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(structuredData, undefined, 2)}</script>
    </Helmet>
  );
}

BlogPostingStructuredData.propTypes = {
  articleDescription: PropTypes.string.isRequired,
  articlePublishDate: PropTypes.string.isRequired,
  articleUrl: PropTypes.string.isRequired,
  articleWordCount: PropTypes.number.isRequired,
  authorImageHeight: PropTypes.number.isRequired,
  authorImageUrl: PropTypes.string.isRequired,
  authorImageWidth: PropTypes.number.isRequired,
  authorName: PropTypes.string.isRequired,
  authorUrl: PropTypes.string.isRequired,
  currentEdition: PropTypes.string.isRequired,
  featuredImageHeight: PropTypes.number.isRequired,
  featuredImageUrl: PropTypes.string.isRequired,
  featuredImageWidth: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
};

// Module Exports
export { BlogPostingStructuredData };
