// Internal Imports
import { camelCaseKeysDeep } from '../../utils/dataUtils.mjs';
import { authenticatedRequest } from './authenticatedRequest.mjs';

// Local Functions
const fetchCurrentUser = ({ locale }) =>
  authenticatedRequest({
    path: `v1.2.0/${locale}/users/me`,
    verb: 'GET',
  }).then((response) => camelCaseKeysDeep(response.data.user));

// Module Exports
export { fetchCurrentUser };
