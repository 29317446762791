// Internal Imports
import * as contentTypes from './content/index.mjs';
import * as taxonomyTypes from './taxonomy.mjs';

// Local Variables
const FRENCH = 'fr';
const ENGLISH = 'en';
// British english is currently in use only for in-product magazine
// see docs/in-product-locale.md for more information
const ENGLISH_BRITISH = 'en_GB';
const ENGLISH_USA = 'en-US';
const GERMAN = 'de';
const ITALIAN = 'it';
const SPANISH = 'es';
const POLISH = 'pl';
const PORTUGUESE = 'pt';
const SWEDISH = 'sv';

const defaultLanguage = ENGLISH;

const languageCodePostTypeMap = {
  [ENGLISH]: contentTypes.POST,
  [ENGLISH_USA]: contentTypes.POST,
  [FRENCH]: contentTypes.FRENCH,
  [GERMAN]: contentTypes.GERMAN,
  [ITALIAN]: contentTypes.ITALIAN,
  [POLISH]: contentTypes.POLISH,
  [PORTUGUESE]: contentTypes.PORTUGUESE,
  [SPANISH]: contentTypes.SPANISH,
  [SWEDISH]: contentTypes.SWEDISH,
};

const languageCodePostTypeWithEnglishMap = {
  ...languageCodePostTypeMap,
  [ENGLISH]: 'english_edition',
  [ENGLISH_USA]: 'english_USA_edition',
};

const languageCodeTagTaxonomyMap = {
  [ENGLISH]: taxonomyTypes.ENGLISH_TAGS,
  [ENGLISH_USA]: taxonomyTypes.ENGLISH_TAGS,
  [FRENCH]: taxonomyTypes.FRENCH_TAGS,
  [GERMAN]: taxonomyTypes.GERMAN_TAGS,
  [ITALIAN]: taxonomyTypes.ITALIAN_TAGS,
  [POLISH]: taxonomyTypes.POLISH_TAGS,
  [PORTUGUESE]: taxonomyTypes.PORTUGUESE_TAGS,
  [SPANISH]: taxonomyTypes.SPANISH_TAGS,
  [SWEDISH]: taxonomyTypes.SWEDISH_TAGS,
};

const languageCodeCategoryTaxonomyMap = {
  [ENGLISH]: taxonomyTypes.ENGLISH_CATEGORIES,
  [ENGLISH_USA]: taxonomyTypes.ENGLISH_CATEGORIES,
  [FRENCH]: taxonomyTypes.FRENCH_CATEGORIES,
  [GERMAN]: taxonomyTypes.GERMAN_CATEGORIES,
  [ITALIAN]: taxonomyTypes.ITALIAN_CATEGORIES,
  [POLISH]: taxonomyTypes.POLISH_CATEGORIES,
  [PORTUGUESE]: taxonomyTypes.PORTUGUESE_CATEGORIES,
  [SPANISH]: taxonomyTypes.SPANISH_CATEGORIES,
  [SWEDISH]: taxonomyTypes.SWEDISH_CATEGORIES,
};

const languageCodesArray = [
  ENGLISH,
  ENGLISH_BRITISH,
  ENGLISH_USA,
  FRENCH,
  GERMAN,
  ITALIAN,
  POLISH,
  PORTUGUESE,
  SPANISH,
  SWEDISH,
];

const languageCodeEnglishFullNames = {
  [ENGLISH]: 'English',
  [ENGLISH_USA]: 'English',
  [FRENCH]: 'French',
  [GERMAN]: 'German',
  [ITALIAN]: 'Italian',
  [POLISH]: 'Polish',
  [PORTUGUESE]: 'Portuguese',
  [SPANISH]: 'Spanish',
  [SWEDISH]: 'Swedish',
};

const englishAlternatives = [ENGLISH_BRITISH, ENGLISH_USA];

// Module Exports
export {
  defaultLanguage,
  ENGLISH,
  ENGLISH_BRITISH,
  ENGLISH_USA,
  englishAlternatives,
  FRENCH,
  GERMAN,
  ITALIAN,
  languageCodeCategoryTaxonomyMap,
  languageCodeEnglishFullNames,
  languageCodePostTypeMap,
  languageCodePostTypeWithEnglishMap,
  languageCodesArray,
  languageCodeTagTaxonomyMap,
  POLISH,
  PORTUGUESE,
  SPANISH,
  SWEDISH,
};
