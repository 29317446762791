// External Imports
import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// Internal Imports
import { getGoBaseUrl, isTrackingDisabled } from '../../utils/url.mjs';
import { selectIsBabbelTrackingUuidSet } from '../../selectors/selectIsBabbelTrackingUuidSet.mjs';
import { isInProduct } from '../../utils/inProduct.mjs';

// Component Definition
class CampaignsTracker extends Component {
  constructor(props) {
    super(props);
    this.campaignsTracker = undefined;
  }

  componentDidMount() {
    this.trackPageView();
  }

  componentDidUpdate(previousProps) {
    const { isTrackingUuidSet, shortlinkCode } = this.props;
    if (
      shortlinkCode !== previousProps.shortlinkCode ||
      isTrackingUuidSet !== previousProps.isTrackingUuidSet
    ) {
      this.trackPageView();
    }
  }

  async trackPageView() {
    const { isTrackingUuidSet, shortlinkCode } = this.props;

    if (isTrackingDisabled || isInProduct(window.location.search)) {
      return;
    }

    if (!this.campaignsTracker) {
      // TODO: Update package.json in the @lessonnine/marketing-tracker package itself to correctly point to the file for ESM
      const marketingTracker = await import('@lessonnine/marketing-tracker/dist/index.js');
      this.campaignsTracker = new marketingTracker.CampaignsTracker({
        host: getGoBaseUrl(),
      });
    }
    if (isTrackingUuidSet) {
      this.campaignsTracker.trackPageview(shortlinkCode);
    }
  }

  // eslint-disable-next-line class-methods-use-this -- this is a renderless component
  render() {
    return null; // eslint-disable-line unicorn/no-null -- React expects non-rendering to use null
  }
}

CampaignsTracker.propTypes = {
  isTrackingUuidSet: PropTypes.bool.isRequired,
  shortlinkCode: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  isTrackingUuidSet: selectIsBabbelTrackingUuidSet(state),
});

const withRedux = connect(mapStateToProps);
const CampaignsTrackerHoc = withRedux(CampaignsTracker);

// Module Exports
export { CampaignsTrackerHoc as CampaignsTracker };
