// External Imports
import moment from 'moment';
import enLocale from 'moment/locale/en-gb.js';
import esLocale from 'moment/locale/es.js';
import deLocale from 'moment/locale/de.js';
import itLocale from 'moment/locale/it.js';
import frLocale from 'moment/locale/fr.js';
import plLocale from 'moment/locale/pl.js';
import ptLocale from 'moment/locale/pt.js';
import svLocale from 'moment/locale/sv.js';

// Internal Imports
import {
  ENGLISH_USA,
  ENGLISH,
  FRENCH,
  GERMAN,
  ITALIAN,
  POLISH,
  PORTUGUESE,
  SPANISH,
  SWEDISH,
} from '../config/types/language.mjs';

// Local Variables
const DATE_FORMAT = 'MMMM D, YYYY';
const DATE_FORMAT_FEATURE = 'MMMM D, YYYY | h:mm A';

const momentLocaleTypeMap = {
  [ENGLISH]: enLocale,
  [ENGLISH_USA]: enLocale,
  [FRENCH]: frLocale,
  [GERMAN]: deLocale,
  [ITALIAN]: itLocale,
  [POLISH]: plLocale,
  [PORTUGUESE]: ptLocale,
  [SPANISH]: esLocale,
  [SWEDISH]: svLocale,
};

// Local Functions
/**
 * Return the correct date format for an Article page
 *
 * @param  {string} date - Post Date
 * @param  {string} languageCode - Edition Language
 * @return {string}
 */
function formatDate(date, languageCode) {
  const locale = momentLocaleTypeMap[languageCode];

  moment.locale(languageCode, locale);
  const formatString =
    languageCode === ENGLISH || languageCode === ENGLISH_USA ? 'MMMM D, YYYY' : 'DD/MM/YYYY';
  const formattedDate = moment(date).format(formatString);

  return formattedDate;
}

/**
 * Return the correct date format depending on how long ago the content was published
 *
 * @param  {number} timestamp - Content timestamp
 * @param {string} format
 * @return {object}
 */
function getMomentFormatProps(timestamp, format = DATE_FORMAT) {
  const current = moment();
  const comparison = moment(timestamp);
  const diff = current.diff(comparison, 'minutes');

  // Greater than 30 minutes difference
  if (diff > 30 || comparison.isAfter(current)) {
    return { format };
  }

  return { fromNow: true };
}

/**
 * Build an object representing the local time converted to UTC
 *
 * @returns {object} An object representing the local time converted to UTC
 */
function getCurrentDateUTC() {
  const currentDate = new Date();
  return {
    year: currentDate.getUTCFullYear(),
    month: currentDate.getUTCMonth() + 1, // eslint-disable-line sort-keys -- Normalize because month is zero-indexed
    day: currentDate.getUTCDate(), // eslint-disable-line sort-keys -- reads easier sort by measure
    hour: currentDate.getUTCHours(),
    minute: currentDate.getUTCMinutes(),
    second: currentDate.getUTCSeconds(),
    millisecond: currentDate.getUTCMilliseconds(), // eslint-disable-line sort-keys -- reads easier sort by measure
  };
}

// Module Exports
export { DATE_FORMAT, DATE_FORMAT_FEATURE, formatDate, getMomentFormatProps, getCurrentDateUTC };
