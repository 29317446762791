// External Imports
import { call, put, select } from 'redux-saga/effects';

// Internal Imports
import {
  AUTHENTICATE_USER_REQUESTED,
  AUTHENTICATE_USER_SUCCEEDED,
  AUTHENTICATE_USER_ERRORED,
  FETCH_USER_SUCCEEDED,
  FETCH_USER_ERRORED,
} from '../config/types/action.mjs';
import { selectLocale } from '../selectors/selectLocale.mjs';
import { lazyApiClient } from '../services/babbelApi/apiClient.mjs';
import { fetchCurrentUser } from '../services/babbelApi/currentUser.mjs';

// Local Functions
function* fetchUser() {
  try {
    yield put({ type: AUTHENTICATE_USER_REQUESTED });
    const apiClient = yield call(lazyApiClient);
    const uuid = yield call(apiClient.authenticate.bind(apiClient));
    yield put({ payload: { uuid }, type: AUTHENTICATE_USER_SUCCEEDED });
  } catch (error) {
    yield put({ payload: error, type: AUTHENTICATE_USER_ERRORED });
  }
  try {
    const locale = yield select(selectLocale);
    const userData = yield call(fetchCurrentUser, { locale });
    yield put({ payload: userData, type: FETCH_USER_SUCCEEDED });
  } catch (error) {
    yield put({ payload: error, type: FETCH_USER_ERRORED });
  }
}

// Module Exports
export { fetchUser };
