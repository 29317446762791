// External Imports
import merge from 'lodash/fp/merge.js';
import set from 'lodash/fp/set.js';

// Internal Imports
import { searchTypes, POST } from '../../config/types/content/index.mjs';
import { landingPageTypePathTypeMap } from '../../config/types/landingPage.mjs';
import { languageCodePostTypeMap, ENGLISH_USA } from '../../config/types/language.mjs';
import {
  HOME,
  CONTENT_ITEM_SINGLE,
  TERM_ARCHIVE,
  AUTHOR_ARCHIVE,
  SEARCH_ARCHIVE,
  CATEGORY_ARCHIVE,
} from '../../config/types/path.mjs';
import { ENGLISH_TAGS, ENGLISH_CATEGORIES } from '../../config/types/taxonomy.mjs';
import { getEditionFromLocale } from '../../selectors/selectEdition.mjs';
import { selectTaxonomy } from '../../selectors/selectTerm.mjs';
import { applyArchiveArguments, WordPressRestApiService } from '../wordPressRestApi/index.mjs';
import {
  createContentPayload,
  makeRequestWithRetry,
  mergeTerms,
} from '../wordPressRestApi/util.mjs';

// Local Functions
const getTermHandlerName = (taxonomy) => {
  if (ENGLISH_TAGS === taxonomy) {
    return 'tags';
  }

  if (ENGLISH_CATEGORIES === taxonomy) {
    return 'categories';
  }

  return taxonomy;
};

// Local Classes
class BabbelWordPressRestApi extends WordPressRestApiService {
  constructor(overrides) {
    super(overrides);

    this.options = merge(this.options, {
      babbelRoot: 'babbel/v1',
      landingPage: 'landing-page/(?P<landingPageType>)/',
    });

    const { landingPage, babbelRoot } = this.options;
    this.wp.landingPage = this.wp.registerRoute(babbelRoot, landingPage);

    // Bindings
    this.fetchArchive = this.fetchArchive.bind(this);
  }

  /**
   * Execute http request to resolve a content archive by route match.
   * @param {object} match   route match
   * @param {number} perPage number of entities per page
   * @param {number} page    page number
   * @returns {Promise}      promise that resolves a content action payload
   */
  fetchArchive(match, perPage, page) {
    if (match?.isLanding) {
      return this.fetchLandingPage(match, perPage, page);
    }

    return makeRequestWithRetry(() => super.fetchArchive(match, perPage, page));
  }

  fetchTermArchive = async (match, perPage, page) => {
    const {
      params: { slug, language },
    } = match;
    const edition = getEditionFromLocale(language);
    const queryTaxonomy = selectTaxonomy(match, edition);
    const handler = this.getHandlerByMatch(match);
    const termHandlerName = getTermHandlerName(queryTaxonomy);
    // Terms requested directly will include the "full" term object shape.
    const terms = await this.fetchTerms(termHandlerName, slug);

    if (terms.length === 0) {
      return { meta: {} };
    }

    // Apply pagination to query.
    handler.perPage(perPage).page(page).embed();

    const response = await makeRequestWithRetry(() =>
      handler[termHandlerName](terms.map(({ id }) => id)),
    );
    const payload = createContentPayload(response, page, match);
    return mergeTerms(payload, queryTaxonomy, terms);
  };

  fetchAuthorArchive = async (match, perPage, page) => {
    const {
      params: { slug, language },
      query,
    } = match;
    const queryLanguage = getEditionFromLocale(language);
    const handler = this.wp.author().authorSlug(slug).param('language', queryLanguage);
    const response = await applyArchiveArguments(handler, query, perPage, page);

    if (!response.posts) {
      return [];
    }

    const { _paging: paging, posts } = response;
    // Monkey patch pagination fields.
    posts._paging = paging; // eslint-disable-line no-underscore-dangle -- Wordpress API shape
    return posts;
  };

  /**
   * Execute http request to resolve a landing page by route match.
   *
   * @param {object} match   route match
   * @param {number} perPage number of entities per page
   * @param {number} page    page number
   * @returns {Promise}      promise that resolves a content action payload
   */
  fetchLandingPage = async (match, perPage, page) => {
    const {
      pathType,
      query,
      params: { language },
    } = match;
    const queryLanguage = getEditionFromLocale(language);
    const landingPageType = landingPageTypePathTypeMap[pathType];
    const contentType = languageCodePostTypeMap[queryLanguage];
    let handler = this.wp.landingPage();
    let response = [];
    let content = [];

    try {
      handler = applyArchiveArguments(handler, query, perPage, page);

      // Add country code
      if (ENGLISH_USA === language) {
        handler.param('country', 'us');
      }

      content = await makeRequestWithRetry(() =>
        handler.landingPageType(landingPageType).param('language', queryLanguage).get(),
      );
    } catch (error) {
      WordPressRestApiService.checkError(error);
    }

    if (content.length === 0) {
      return { meta: {} };
    }

    // Add landing page type to each item
    response = content.map((contentItem) => ({
      ...contentItem,
      landingPageType,
      language,
    }));
    // Set pagination
    response._paging = content._paging; // eslint-disable-line no-underscore-dangle -- Wordpress API shape
    const payload = createContentPayload(response, page, match);

    return set(
      'meta.refs',
      payload[contentType].map(({ id, metadata }) => ({ id, type: metadata.type })),
      payload,
    );
  };

  /**
   * @param {object}   match react-router route match
   * @returns {object}       node-wpapi handler
   */
  getHandlerByMatch = (match) => {
    if (!match) {
      // eslint-disable-next-line unicorn/no-null -- useful for optional return, the undefined alternative breaks many rules
      return null;
    }
    const { params, pathType, query } = match;
    const contentType = languageCodePostTypeMap[params.language] || params.contentType;
    switch (pathType) {
      case HOME:
        return this.wp.landingPage();

      case CONTENT_ITEM_SINGLE:
        // This logic is necessary because the content type is `post` but the
        // endpoint is `posts` (plural), unlike every other content type.
        return POST === contentType ? this.wp.posts() : this.wp[contentType]();

      case AUTHOR_ARCHIVE:
      case CATEGORY_ARCHIVE:
      case TERM_ARCHIVE: {
        const types = contentType ? [contentType] : searchTypes;
        return this.getMultipleHandler().type(types);
      }

      case SEARCH_ARCHIVE: {
        const types = contentType ? [contentType] : searchTypes;
        const searchTerm = query.s;
        return this.getMultipleHandler().type(types).search(searchTerm);
      }

      default:
        // eslint-disable-next-line unicorn/no-null -- useful for optional return
        return null;
    }
  };
}

// Module Exports
export { BabbelWordPressRestApi };
