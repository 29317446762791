// External Imports
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { stringify } from 'query-string';
import isNumber from 'lodash/isNumber';
import PropTypes from 'prop-types';
import React from 'react';
import { Header, pxToRem, Stack, styled, Text } from '@lessonnine/design-system.lib';

// Internal Imports
import { ButtonControl } from '../../FilterMenu/ButtonControl.jsx';
import { CONTENT_ARCHIVE } from '../../../config/types/prop.mjs';
import { ContentListWithLoader } from '../../ContentList/ContentListWithLoader.jsx';
import { createSelectTranslation } from '../../../selectors/createSelectTranslation.mjs';
import { selectActivePagination } from '../../../selectors/selectActivePagination.mjs';
import { I18n } from '../../I18n/I18n.jsx';
import { ListViewContext } from '../../../context/listView.mjs';
import { Meta } from '../../Meta/Meta.jsx';
import { options } from '../../../config/reactRedux.mjs';
import { Pagination } from '../../Pagination/Pagination.jsx';
import { SearchField } from './SearchField.jsx';
import {
  selectSearchPath,
  selectSearchParameters,
  selectSearchResultsShowing,
} from '../../../selectors/selectSearch.mjs';
import { siteInnerMaxWidth } from '../../../config/css/ui.mjs';

// Local Variables
const StackWithMaxWidth = styled(Stack)`
  max-width: ${pxToRem(siteInnerMaxWidth)};
`;

const CenteredText = styled(Text)`
  text-align: center;
`;

// Component Definition
function SearchArchiveBase({
  pagination: { hasMore, currentPage, totalPages, paginationLinks },
  pageMeta: {
    controls: { order },
  },
  onChangeFilter,
  resultsShowing,
  items,
  total,
  search,
  params,
  getTranslation,
}) {
  const hasItems = items.length > 0;
  const hasResults = !!total && isNumber(total) && params.s.trim();
  const showPagination = hasMore || (currentPage === totalPages && totalPages > 1);

  const searchPageName = getTranslation('search', 'Search Articles');
  const magazineName = getTranslation('meta-title', 'Babbel Magazine');

  return (
    <StackWithMaxWidth gap={32}>
      <Meta title={`${searchPageName} - ${magazineName}`} />
      {/* eslint-disable-next-line react/jsx-pascal-case -- design-system's API */}
      <Header.h1>
        {params.s ? (
          <>
            <I18n field="search-results-for" defaultText="Search Results for" />
            {` "${params.s}"`}
          </>
        ) : (
          <I18n field="search" defaultText="Search" />
        )}
      </Header.h1>
      <SearchField onSubmit={search} params={params} />
      <Stack>
        {hasResults && (
          <Stack gap={32}>
            {!!total && resultsShowing.length > 0 && (
              <Text fontSize="big" fontWeight={700}>
                <I18n field="showing_search1" defaultText="Showing" />
                {` ${resultsShowing[0]}-${resultsShowing[1]} `}
                <I18n field="of_search2" defaultText="of" />
                {` ${total} `}
                <I18n field="results_search3" defaultText="Results" />
              </Text>
            )}
            <ButtonControl
              label={<I18n field="sort-by" defaultText="Sort By" />}
              name="order"
              options={order.options}
              selected={order.selected || 'relevance'}
              onChange={onChangeFilter}
            />
            {hasItems && (
              <ListViewContext.Provider value="linear">
                <ContentListWithLoader items={items} loading={!hasItems} />
              </ListViewContext.Provider>
            )}
            {showPagination && (
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                links={paginationLinks}
              />
            )}
          </Stack>
        )}
        {!hasResults && (
          <Stack alignItems="center" gap={48} padding={[48, 0]}>
            {/* eslint-disable-next-line react/jsx-pascal-case -- design-system's API */}
            <Header.h2>
              <I18n field="no-search-results1" defaultText="Uh Oh!" />
            </Header.h2>
            <Stack as="p" gap={12}>
              <CenteredText fontSize="big">
                <I18n
                  field="no-search-results2"
                  defaultText="We can't seem to find any results for:"
                />
              </CenteredText>
              <CenteredText fontSize="big" fontWeight={700}>
                {params.s}
              </CenteredText>
            </Stack>
            <CenteredText fontSize="big">
              <I18n field="no-search-results3" defaultText="Try searching for something else." />
            </CenteredText>
          </Stack>
        )}
      </Stack>
    </StackWithMaxWidth>
  );
}

SearchArchiveBase.propTypes = {
  ...CONTENT_ARCHIVE,
  onChangeFilter: PropTypes.func.isRequired,
  params: PropTypes.shape({
    s: PropTypes.string,
  }).isRequired,
  resultsShowing: PropTypes.arrayOf(PropTypes.number).isRequired,
  search: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  const { total } = selectActivePagination(state);
  return {
    getTranslation: createSelectTranslation(state),
    params: selectSearchParameters(state),
    resultsShowing: selectSearchResultsShowing(state),
    searchPath: selectSearchPath(state),
    total: total || 0,
  };
};

const mapDispatchToProps = (dispatch) => ({
  search: (searchPath) => (parameters) => {
    const queryString = stringify(parameters, { arrayFormat: 'bracket' });
    dispatch(push(`${searchPath}?${queryString}`));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...ownProps,
  search: dispatchProps.search(stateProps.searchPath),
});

const withRedux = connect(mapStateToProps, mapDispatchToProps, mergeProps, options);
const SearchArchive = withRedux(SearchArchiveBase);

// Module Exports
export { SearchArchive };
