// External Imports
import mergeWith from 'lodash/fp/mergeWith';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { styled, Text, pxToRem } from '@lessonnine/design-system.lib';
import tokens from '@lessonnine/design-tokens.lib/dist/json/variables.json';

// Internal Imports
import { ENGLISH, ENGLISH_USA } from '../../config/types/language.mjs';
import { I18n } from '../I18n/I18n.jsx';
import { MENU, LANGUAGES } from '../../config/types/prop.mjs';
import { breakpoints } from '../../config/css/breakpoints.mjs';
import { DsLink } from '../Link/DsLink.jsx';

const Heading = styled(Text)`
  margin-bottom: ${pxToRem(15)};
`;

const List = styled.ul`
  ${(props) =>
    props.copyright &&
    `
      align-items: center;
      display: flex;
      flex-flow: row wrap;
      flex-direction: row;
    
      /* we are using a design-system breakpoint in the Layout rendering this element, so for it to work correctly we need the same exact breakpoint here */
      @media (max-width: ${tokens.viewports.breakpoints.medium}) {
        justify-content: center;
      } 
    `}
`;

const ListItemDefault = styled.li`
  padding: ${pxToRem(4)} 0;
`;

const ListItemCopyright = styled.li`
  display: flex;
  justify-content: center;
  margin-right: ${pxToRem(10)};
  padding: ${pxToRem(10)} 0;

  @media ${breakpoints.tabletPortrait.min} {
    margin-right: ${pxToRem(30)};
  }
`;

// Component Definition
function FooterNav({ headingTranslation, heading, copyright, items, currentLanguage }) {
  const ListItem = copyright ? ListItemCopyright : ListItemDefault;
  return (
    <>
      {heading && (
        <Heading fontSize="medium" fontWeight="600" letterSpacing="0.06">
          <I18n field={headingTranslation} defaultText={heading} />
        </Heading>
      )}
      <List copyright={copyright}>
        {copyright && (
          <ListItem>
            <Text>{`© ${moment().format('YYYY')} Babbel GmbH`}</Text>
          </ListItem>
        )}
        {items.map((item) => {
          const translatedItem = item.translations ? item.translations[currentLanguage] : {};

          // Don't show entries that do not contain all necessary display data
          // NOTE: English does not use the translations object for this data, so this check isn't needed
          if (
            currentLanguage !== ENGLISH &&
            currentLanguage !== ENGLISH_USA &&
            (!translatedItem || !translatedItem.label || !translatedItem.fullUrl)
          ) {
            return null; // eslint-disable-line unicorn/no-null -- React expects null for empty renders
          }

          // Normal merge isn't working here because empty
          // values are '' in the translated item
          const mergedItem = mergeWith((value, source) => source || value, item, translatedItem);

          const { id, fullUrl, label } = mergedItem;
          if (!label || !fullUrl) {
            return null; // eslint-disable-line unicorn/no-null -- React expects null for empty renders
          }

          return (
            <ListItem key={id}>
              <DsLink to={fullUrl} underlined={false}>
                {label}
              </DsLink>
            </ListItem>
          );
        })}
      </List>
    </>
  );
}

FooterNav.propTypes = {
  copyright: PropTypes.bool,
  currentLanguage: LANGUAGES,
  heading: PropTypes.string,
  headingTranslation: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape(MENU)).isRequired,
};

FooterNav.defaultProps = {
  copyright: false,
  currentLanguage: ENGLISH,
  heading: '',
  headingTranslation: '',
};

// Module Exports
export { FooterNav };
