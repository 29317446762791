// External Imports
import { Component } from 'react';
import PropTypes from 'prop-types';

// Internal Imports
import { getGoBaseUrl, isTrackingDisabled } from '../../utils/url.mjs';
import { isInProduct } from '../../utils/inProduct.mjs';

// Local Functions
function getAuthorSlug() {
  const authorLinkElement = document.querySelector('link[rel="author"][href]');
  if (!authorLinkElement) {
    return '';
  }
  const authorSlug = authorLinkElement.getAttribute('href').split('/').pop();
  return authorSlug || '';
}

function sendSpaNavigationData() {
  const authorSlug = getAuthorSlug();

  window.dataLayer?.push?.({
    author: authorSlug,
    event: 'pageview',
  });
}

// Component Definition
class MarketingTracker extends Component {
  componentDidMount() {
    if (!isTrackingDisabled && !isInProduct(window.location.search)) {
      window.requestAnimationFrame(() => {
        this.trackingInitializedPromise = this.initTracking();
      });
    }
  }

  componentDidUpdate(previousProps) {
    const { isContentLoading, location } = this.props;

    if (!isTrackingDisabled && !isInProduct(window.location.search)) {
      // Detect when the loading spinner disappears (meaning content is ready)
      const isUncachedPageLoad = previousProps.isContentLoading && !isContentLoading;

      // Detect when a page cached in the Redux store is being re-visited (isContentLoading is never true)
      const isReduxCachedPageLoad =
        !previousProps.isContentLoading &&
        !isContentLoading &&
        previousProps.location.pathname !== location.pathname;

      if (isUncachedPageLoad || isReduxCachedPageLoad) {
        this.trackingInitializedPromise.then(() =>
          window.requestAnimationFrame(() => sendSpaNavigationData()),
        );
      }
    }
  }

  initTracking = () => {
    const { magazineEditionLanguage } = this.props;

    // TODO: Update package.json in the @lessonnine/marketing-tracker package itself to correctly point to the file for ESM
    return import('@lessonnine/marketing-tracker/dist/index.js').then(async (marketingTracker) => {
      // Add page's author name to Google Tag Manager dataLayer queue or empty string if no author
      const authorSlug = getAuthorSlug();
      window.dataLayer = [{ author: authorSlug }];
      try {
        // Load Google Tag Manager
        const googleTagManager = marketingTracker.gtm;
        await googleTagManager.load();
        await googleTagManager.init({ locale: magazineEditionLanguage });

        const campaignsTracker = new marketingTracker.CampaignsTracker({
          // Babbel Campaigns App ("host" really refers to the expected URL prefix, not the hostname)
          host: getGoBaseUrl(),
        });
        campaignsTracker.trackUserClick();
      } catch (error) {
        if (error.message !== 'Page is private so GTM will not be called from this page') {
          throw error;
        }
      }
    });
  };

  // eslint-disable-next-line class-methods-use-this -- this is a renderless component
  render() {
    return null; // eslint-disable-line unicorn/no-null -- React expects null for empty rendering
  }
}

// Component Metadata
MarketingTracker.propTypes = {
  isContentLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types -- use a TypeScript type eventually
  magazineEditionLanguage: PropTypes.string.isRequired,
};

// Module Exports
export { MarketingTracker };
