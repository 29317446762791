// Internal Imports
import {
  getApigatewayBaseUrl,
  getAwsSessionEndpoint,
  getAwsSessionMethod,
} from '../../utils/url.mjs';

// Local Functions
const lazyApiClient = () =>
  import('@lessonnine/api-client.js').then((apiClientModule) => {
    const ApiClient = apiClientModule.default.default;
    return new ApiClient({
      awsSessionEndpoint: getAwsSessionEndpoint(),
      awsSessionMethod: getAwsSessionMethod().toLowerCase(),
      baseUrl: getApigatewayBaseUrl(),
    });
  });

// Module Exports
export { lazyApiClient };
