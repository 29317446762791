// Local Variables
const ARTICLE_LINK_CLICKED = 'ARTICLE_LINK_CLICKED';
const ARTICLE_SHARED = 'ARTICLE_SHARED';
const AUTHENTICATE_USER_ERRORED = 'AUTHENTICATE_USER_ERRORED';
const AUTHENTICATE_USER_REQUESTED = 'AUTHENTICATE_USER_REQUESTED';
const AUTHENTICATE_USER_SUCCEEDED = 'AUTHENTICATE_USER_SUCCEEDED';
const CALL_TO_ACTION_CLICKED = 'CALL_TO_ACTION_CLICKED';
const FETCH_USER_ERRORED = 'FETCH_USER_ERRORED';
const FETCH_USER_REQUESTED = 'FETCH_USER_REQUESTED';
const FETCH_USER_SUCCEEDED = 'FETCH_USER_SUCCEEDED';
const QUERY_AB_TESTER = 'QUERY_AB_TESTER';
const RECEIVE_ERROR = 'RECEIVE_ERROR';
const RECEIVE_PRIMARY_CONTENT = 'RECEIVE_PRIMARY_CONTENT';
const RECEIVE_SECONDARY_CONTENT = 'RECEIVE_SECONDARY_CONTENT';
const RECEIVE_SITE_OPTIONS = 'RECEIVE_SITE_OPTIONS';
const REQUEST_ARCHIVE = 'REQUEST_ARCHIVE';
const REQUEST_CONTENT_ITEM = 'REQUEST_CONTENT_ITEM';
const REQUEST_LATEST = 'REQUEST_LATEST';
const REQUEST_LATEST_NEXT_PAGE = 'REQUEST_LATEST_NEXT_PAGE';
const REQUEST_NEXT_PAGE = 'REQUEST_NEXT_PAGE';
const REQUEST_NEXT_PAGE_FETCH_INITIATED = 'REQUEST_NEXT_PAGE_FETCH_INITIATED';
const REQUEST_SEARCH = 'REQUEST_SEARCH';
const REQUEST_SITE_OPTIONS = 'REQUEST_SITE_OPTIONS';
const RECEIVE_SITE_OPTIONS_ERROR = 'RECEIVE_SITE_OPTIONS_ERROR';

// Module Exports
export {
  ARTICLE_LINK_CLICKED,
  ARTICLE_SHARED,
  AUTHENTICATE_USER_ERRORED,
  AUTHENTICATE_USER_REQUESTED,
  AUTHENTICATE_USER_SUCCEEDED,
  CALL_TO_ACTION_CLICKED,
  FETCH_USER_ERRORED,
  FETCH_USER_REQUESTED,
  FETCH_USER_SUCCEEDED,
  QUERY_AB_TESTER,
  RECEIVE_ERROR,
  RECEIVE_PRIMARY_CONTENT,
  RECEIVE_SECONDARY_CONTENT,
  RECEIVE_SITE_OPTIONS,
  RECEIVE_SITE_OPTIONS_ERROR,
  REQUEST_ARCHIVE,
  REQUEST_CONTENT_ITEM,
  REQUEST_LATEST_NEXT_PAGE,
  REQUEST_LATEST,
  REQUEST_NEXT_PAGE_FETCH_INITIATED,
  REQUEST_NEXT_PAGE,
  REQUEST_SEARCH,
  REQUEST_SITE_OPTIONS,
};
