// Internal Imports
import { isNode } from '../utils/environment.mjs';
import { getRuntimeConfiguration } from '../utils/getRuntimeConfiguration.mjs';
import { BabbelWordPressRestApi } from './babbelWordPressRestApi/index.mjs';

// Local Variables
const defaultWordpressServiceConfig = {
  options: getRuntimeConfiguration('WORDPRESS_API_OPTIONS'),
  pluginRoot: getRuntimeConfiguration('WORDPRESS_API_PLUGIN_ROOT'),
  root: getRuntimeConfiguration('WORDPRESS_API_ROOT'),
};

const wordpressConfig = isNode()
  ? {
      ...defaultWordpressServiceConfig,
      password: getRuntimeConfiguration('WORDPRESS_API_PASSWORD'),
      // Viewing drafts or revisions requires authentication.
      username: getRuntimeConfiguration('WORDPRESS_API_USERNAME'),
    }
  : defaultWordpressServiceConfig;

// Local Functions
function locateRemoteService() {
  // const serviceName = getRuntimeConfiguration('WORDPRESS_SERVICE_NAME') || 'babbelWordPressRestApi';
  // const module = await import(`./${serviceName}/index`);
  // const Service = module.default;
  // switch (serviceName) {
  //   case 'wordPressRestApi':
  //   case 'babbelWordPressRestApi':
  //   default:
  //     return new Service(wordpressConfig);
  // }

  return new BabbelWordPressRestApi(wordpressConfig);
}

// Module Exports
export { locateRemoteService };
